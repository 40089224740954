import React from "react";
import axios from "axios";
import { UserInformation } from "../models/UserInformation";
import { setUserProperties, setUserId, logEvent, AmplitudeEvent } from "../../utils/analytics";


export interface AuthenticateState {
  data: UserInformation;
  isLoading: boolean;
}

const useAuthenticate = (
  userId: string,
  userToken: string,
  kitchenId: string
): AuthenticateState => {
  const [userInformation, setUserInformation] = React.useState<UserInformation>(
    { isAuthenticated: false }
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  React.useEffect(() => {    
    const authenticate = async () => {
      setIsLoading(true);
      const user = await axios.get<UserInformation>(
        `${process.env.REACT_APP_API_URL}/api/v1/unsecure/${kitchenId}/${userId}/${userToken}`
      );
      if (user.data.kitchen && user.data.user) {
        localStorage.setItem('ks.kitchen', JSON.stringify(user.data.kitchen));
        localStorage.setItem('ks.user', JSON.stringify(user.data.user));
        localStorage.setItem('ks.userToken', userToken);
        setUserId(user.data.user?.document);
        setUserProperties({ selectedKitchen: kitchenId });
        logEvent(AmplitudeEvent.EnterMiseEnPlace)
      }
      setUserInformation(user.data);
      setIsLoading(false);
    };

    authenticate();
  }, [userId, userToken, kitchenId]);

  return {
    data: userInformation,
    isLoading,
  };
};

export default useAuthenticate;
