import Typography from '@mui/material/Typography';
import React from 'react';
import { Grid } from '@mui/material';

interface pageTitleProps {
  title: string;
  description: string;
}

const PageTitle = (props: pageTitleProps): React.ReactElement => {
  const { title, description } = props;

  return (
    <Grid xs={12} md={6} lg={8} item>
      <Typography color={'#000000'} fontFamily="Poppins" variant={'h1'}>
        {title}
      </Typography>
      <Typography color={'#000000'} fontFamily="Roboto" variant={'body1'} mt={1}>
        {description}
      </Typography>
    </Grid>
  );
};

export default PageTitle;
