import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import Task from './Task';

interface StyledDivProps {
  isDraggingOver: boolean;
}

const Container = styled.div`
  margin: 16px;
  width: 25%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  color: #7b7b7b;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 4px;
  color: #7b7b7b;
  background: white;
  border-bottom: 12px solid ${(props) => props.color};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const TaskList = styled.div<StyledDivProps>`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: white;
  flex-grow: 1;
  min-height: 100px;
`;

const Column = (props: any): React.ReactElement => {
  
  return (
    <Container>
      <Title color={props.column.color}>{props.column.title}</Title>
      <Droppable droppableId={props.column.id}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {props.tasks.map((task: any, index: number) => (
              <Task key={task._id} task={task} index={index} color={props.column.color} onNextState={props.onNextState}/>
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
};

export default Column;
