import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { LossAndDiscarded } from '../../shared/models/LossAndDiscarded';

interface LossAndDiscardedTableProps {
  lossAndDicarded: LossAndDiscarded[];
  
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F9FAFC',
    color: 'primary.main',
    height: '60px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const LossAndDicardedTable = (props: LossAndDiscardedTableProps): React.ReactElement => {
  const { lossAndDicarded } = props;

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>INSUMO</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">TIPO</StyledTableCell>
                <StyledTableCell align="center">ORIGEN</StyledTableCell>
                <StyledTableCell align="center">CANTIDAD</StyledTableCell>
                <StyledTableCell align="center">ENVIADO BC</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lossAndDicarded?.map((item, index) => (
                <TableRow key={item._id}>
                  <StyledTableCell component="th" scope="row">
                    {item.description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.sku} 
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.type === "LOSS" ? "MERMA" : "BAJA"} 
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.origin.description} 
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.quantity} 
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.sendBC ? "SI":"NO"} 
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default LossAndDicardedTable;
