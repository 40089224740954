import React from 'react';
import axios from 'axios';
import { TaskCategory } from '../models/KitchenTask';

export interface TaskCategoryState {
  data: TaskCategory[];
  isLoading: boolean;
}

const useTaskCategory = (): TaskCategoryState => {
  const [taskCategories, setTaskCategories] = React.useState<TaskCategory[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getCategories = async () => {
      setIsLoading(true);
      const categories = await axios.get<TaskCategory[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/task-categories`,
      );
      setTaskCategories(categories.data);
      setIsLoading(false);
    };

    getCategories();
  }, []);

  return {
    data: taskCategories,
    isLoading,
  };
};

export default useTaskCategory;
