interface ModuleConfig {
  url: string | null;
}

interface ConfigProvider {
  env: string | null;
  kitchenStaff: ModuleConfig;
  kitchenDisplay: ModuleConfig;
  kitchenMonitor: ModuleConfig;
}

const configProvider: ConfigProvider = {
  env: process.env.REACT_APP_ENV ?? null,
  kitchenStaff: {
    url: process.env.REACT_APP_KITCHEN_STAFF_URL ?? null,
  },
  kitchenDisplay: {
    url: process.env.REACT_APP_KITCHEN_DISPLAY_URL ?? null,
  },
  kitchenMonitor: {
    url: process.env.REACT_APP_KITCHEN_MONITOR_URL ?? null,
  },
};

export default configProvider;
