import styled from 'styled-components';

interface StyledDivProps {
  isDragging?: boolean;
}

const TaskContainer = styled.div<StyledDivProps>`
  margin-top: 16px;
`;

const CardHeader = styled.div<StyledDivProps>`
  border-radius: 8px 8px 0px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  transition: background-color 0.2s ease;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${(props) => props.color};
`;
const CardContent = styled.div<StyledDivProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  transition: background-color 0.2s ease;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f1efef;
  padding: 16px;
`;
const FlexDiv = styled.div<StyledDivProps>`
  display: flex;
  margin-top: 20px;
`;

const DescriptionDiv = styled.div<StyledDivProps>`
  display: flex;
`;

const DesciptionLabel = styled.div<StyledDivProps>`
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export { TaskContainer, CardHeader, CardContent, FlexDiv, DescriptionDiv, DesciptionLabel };
