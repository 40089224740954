import React from 'react';
import { useParams } from 'react-router';
import { useMount } from 'react-use';
import updateMiseEnPlaceDetail from '../../shared/hooks/miseEnPlace.hook';
import saveMiseEnPlaceDetail from '../../shared/hooks/miseEnPlaceDetail.hook';
import usePreparation, { PreparationState } from '../../shared/hooks/preparation.hook';
import useTypeDecline from '../../shared/hooks/typeDecline.hook';
import useToolbarTitle from '../../shared/hooks/useToolbarTitle';
import { DialogData } from '../../shared/models/DialogData';
import { Origin } from '../../shared/models/LossAndDiscarded';
import { DataMiseEnPlaceDetailSave } from '../../shared/models/MiseEnPlaceDetail';
import { Preparation } from '../../shared/models/Preparation';
import { Station } from '../../shared/models/Station';
import TimeFrame from '../../shared/models/TimeFrame';
import { KitchenUser } from '../../shared/models/UserInformation';
import { AmplitudeEvent, logEvent } from '../../utils/analytics';
import SelectorPreparationView from './selectorPreparation.view';

const SelectorPreparationContainer = (): React.ReactElement => {
  const toolbarTitle = useToolbarTitle();
  const kitchen: KitchenUser = JSON.parse(localStorage.getItem('ks.kitchen')!!);
  const selectedStation: Station = JSON.parse(localStorage.getItem('ks.selectedStation')!!);
  const selectedTimeFrame: TimeFrame = JSON.parse(localStorage.getItem('ks.selectedtimeFrame')!!);
  const defaultDialogData = { openDialog: false, title: '', message: '', response: false };
  const { stationId, frameTimeId } = useParams();
  const [_preparationsData, _setPreparationData] = React.useState<PreparationState>();
  const [openDialogFinishMiseEnPlace, setOpenDialogFinishMiseEnPlace] =
    React.useState<DialogData>(defaultDialogData);
  const preparationsData = usePreparation(selectedStation._id, selectedTimeFrame._id);
  const [isSaving, setIsSaving] = React.useState<Boolean>(false);
  const [openDialogValidatePreparation, setOpenDialogValidatePreparation] = React.useState(false);
  const [preparedQuantity, setPreparedQuantity] = React.useState(0);
  const [preparation, setPreparation] = React.useState<Preparation>();
  const [unit, setUnit] = React.useState('');
  const [preparedLoss, setPreparedLoss] = React.useState(0);
  const [preparedDiscarded, setPreparedDiscarded] = React.useState(0);
  const listTypeDecline = useTypeDecline();
  const [lossSelected, setLossSelected] = React.useState('');
  const [discaredSelected, setDiscaredSelected] = React.useState('');
  const [discardedDescription, setDiscardedDescription] = React.useState<Origin>();
  const [lossDescription, setLossDescription] = React.useState<Origin>();
  const [lossField, setLossField] = React.useState(false);
  const [discardedField, setdiscardedField] = React.useState(false);
  const [lossTypeDescription, setLossTypeDescription] = React.useState('');
  const [discardedTypeDescription, setDiscardedTypeDescription] = React.useState('');

  useMount(() => {
    toolbarTitle.setTitle(kitchen?.name, 'ESTACIONES');
  });

  const handleCloseDialogMiseEnpLace = () => {
    setOpenDialogFinishMiseEnPlace(defaultDialogData);
  };

  const handleShowRecipe = (preparation: Preparation) => {
    alert('Próximamente instrucciones de preparación para ' + preparation.description);
  };

  const handlePreparation = (preparation: Preparation) => {
    setLossField(false);
    setdiscardedField(false);
    setOpenDialogValidatePreparation(true);
    setPreparation(preparation);
    setUnit(preparation.unit);
  };
  const handleCloseValidatePreparationDialog = () => {
    setdiscardedField(false);
    setLossField(false);
    setOpenDialogValidatePreparation(false);
  };

  const handlePreparedQuantity = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPreparedQuantity(parseInt(event.target.value.toString()));
  };
  const handlePreparedDiscarded = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPreparedDiscarded(parseInt(event.target.value.toString()));
  };
  const handlePreparedLoss = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPreparedLoss(parseInt(event.target.value.toString()));
  };
  const handleFinishPreparation = async () => {
    if (preparedDiscarded !== 0 && discaredSelected === '') {
      setdiscardedField(true);
    } else if (preparedLoss !== 0 && lossSelected === '') {
      setLossField(true);
    } else if (
      preparedDiscarded !== 0 &&
      discaredSelected === '' &&
      preparedLoss !== 0 &&
      lossSelected === ''
    ) {
      setdiscardedField(true);
      setLossField(true);
    } else {
      if (preparation !== undefined) {
        setIsSaving(true);
        const dataMiseEnPlaceDetailSave: DataMiseEnPlaceDetailSave = {
          preparationId: preparation.preparationId,
          timeFrameId: selectedTimeFrame._id!!,
          stationId: selectedStation._id!!,
          suggestedQuantity: 0.0,
          preparedQuantity:
            preparedQuantity !== 0
              ? preparedQuantity
              : preparation.validatedQuantity || preparation.suggestedQuantity || 0,
          loss: preparedLoss !== 0 ? preparedLoss : 0,
          discarded: preparedDiscarded !== 0 ? preparedDiscarded : 0,
          lossType: lossDescription!!,
          discardedType: discardedDescription!!,
          lossTypeDescription: lossTypeDescription !== '' ? lossTypeDescription : '',
          discardedTypeDescription: discardedTypeDescription !== '' ? discardedTypeDescription : '',
        };
        const success = await saveMiseEnPlaceDetail(dataMiseEnPlaceDetailSave);
        logEvent(AmplitudeEvent.FinishPreparation);
        if (success) {
          let _preparationsData = preparationsData;
          _preparationsData.data.forEach((item, index): void => {
            if (item.preparation._id === preparation?._id) {
              item.isPreparationComplete = true;
            }
          });
          const _prepatarions = _preparationsData.data.filter(
            (object) => object.isPreparationComplete === true,
          );
          if (
            (preparationsData.isAllComplete ||
              _prepatarions.length === _preparationsData.data.length) &&
            !preparationsData.isMiseEnPlaceComplete
          ) {
            const response = await updateMiseEnPlaceDetail(
              selectedStation._id!!,
              selectedTimeFrame._id!!,
            );
            setOpenDialogFinishMiseEnPlace({
              openDialog: true,
              title: 'Terminar mise en place',
              message: response.message,
              response: response.isSuccessful,
            });
          }
          setIsSaving(false);
          setOpenDialogValidatePreparation(false);
          _setPreparationData(_preparationsData);
          setPreparation(undefined);
          setPreparedQuantity(0);
          setDiscaredSelected('');
          setLossSelected('');
          setPreparedDiscarded(0);
          setPreparedLoss(0);
          setdiscardedField(false);
          setLossField(false);
          setDiscardedDescription(undefined);
          setLossDescription(undefined);
          setDiscardedTypeDescription('');
          setLossTypeDescription('');
        } else {
        }
      }
    }
  };
  const handleSelectedLoss = (event: { target: { value: React.SetStateAction<string> } }) => {
    setLossDescription(
      listTypeDecline.data.filter((element) => element._id === event.target.value)[0],
    );
    setLossTypeDescription(
      listTypeDecline.data.filter((element) => element._id === event.target.value)[0].description,
    );
    setLossSelected(event.target.value);
  };
  const handleSelectedDiscared = (event: { target: { value: React.SetStateAction<string> } }) => {
    setDiscardedDescription(
      listTypeDecline.data.filter((element) => element._id === event.target.value)[0],
    );
    setDiscardedTypeDescription(
      listTypeDecline.data.filter((element) => element._id === event.target.value)[0].description,
    );
    setDiscaredSelected(event.target.value);
  };
  return (
    <SelectorPreparationView
      stationId={stationId}
      frameTimeId={frameTimeId}
      selectedStation={selectedStation}
      selectedTimeFrame={selectedTimeFrame}
      preparations={preparationsData || _preparationsData}
      handleShowRecipe={handleShowRecipe}
      handlePreparation={handlePreparation}
      dialog={openDialogFinishMiseEnPlace}
      onClickDialog={handleCloseDialogMiseEnpLace}
      isMiseEnPlaceComplete={preparationsData.isMiseEnPlaceComplete}
      isSaving={isSaving}
      isLoading={preparationsData.isLoading}
      openDialogValidatePreparation={openDialogValidatePreparation}
      handleCloseValidatePreparationDialog={handleCloseValidatePreparationDialog}
      handlePreparedQuantity={handlePreparedQuantity}
      handleFinishPreparation={handleFinishPreparation}
      unit={unit}
      handlePreparedDiscarded={handlePreparedDiscarded}
      handlePreparedLoss={handlePreparedLoss}
      listTypeDecline={listTypeDecline.data}
      handleSelectedLoss={handleSelectedLoss}
      lossSelected={lossSelected}
      handleSelectedDiscared={handleSelectedDiscared}
      discaredSelected={discaredSelected}
      country={kitchen.country}
      lossField={lossField}
      discardedField={discardedField}
    />
  );
};

export default SelectorPreparationContainer;
