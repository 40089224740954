import React from 'react';
import { useMount } from 'react-use';
import useItemPreparationMapping from '../../shared/hooks/itemPreparationMapping.hook';
import useLossAndDiscarded from '../../shared/hooks/lossAndDiscarded.hook';
import saveLossesDetail from '../../shared/hooks/lossAndDiscardedDetail';
import useTypeDecline from '../../shared/hooks/typeDecline.hook';
import useToolbarTitle from '../../shared/hooks/useToolbarTitle';
import { ItemPreparationMapping } from '../../shared/models/ItemPreparationMapping';
import { DataLossesSave, LossAndDiscarded, Origin } from '../../shared/models/LossAndDiscarded';
import { KitchenUser } from '../../shared/models/UserInformation';
import LossAndDiscardedPageView from './lossAndDiscardedAdmin.view';

const LossAndDiscardedContainer = (): React.ReactElement => {
  const kitchen: KitchenUser = JSON.parse(localStorage.getItem('ks.kitchen')!!);
  const [openNewLossesDialog, setOpenNewLossesDialog] = React.useState(false);
  const toolbarTitle = useToolbarTitle();
  const [preparedLoss, setPreparedLoss] = React.useState(0);
  const [preparedDiscarded, setPreparedDiscarded] = React.useState(0);
  const listTypeDecline = useTypeDecline();
  const [lossSelected, setLossSelected] = React.useState('');
  const [discaredSelected, setDiscaredSelected] = React.useState('');
  const [discardedDescription, setDiscardedDescription] = React.useState<Origin>();
  const [lossDescription, setLossDescription] = React.useState<Origin>();
  const lossesAndDiscarded = useLossAndDiscarded().data;
  const [losses, setLosses] = React.useState<LossAndDiscarded[]>([]);
  const itemPreparationMapping = useItemPreparationMapping();
  const [itemPreparationMappingSelected, setItemPreparationMapping] =
    React.useState<ItemPreparationMapping | null>(itemPreparationMapping.data[0]);
  const [itemPreparationMappingNameSelected, setItemPreparationMappingNameSelected] =
    React.useState('');
  const [preparationId, setPreparationId] = React.useState('');

  useMount(() => {
    toolbarTitle.setTitle(kitchen?.name, 'MERMAS Y BAJAS');
  });

  const handleCreatedLosses = async () => {
    const dataLossesSave: DataLossesSave = {
      preparationId: preparationId,
      discardedType: discardedDescription,
      lossType: lossDescription,
      loss: preparedLoss,
      discarded: preparedDiscarded,
    };
    const response = await saveLossesDetail(dataLossesSave);
    if (response.isSuccessful) {
      setOpenNewLossesDialog(false);
      setLossDescription(undefined);
      setDiscardedDescription(undefined);
      setLossSelected('');
      setDiscaredSelected('');
      setPreparedLoss(0);
      setPreparedDiscarded(0);
      setLosses(response.losses);
      setPreparationId('');
      setItemPreparationMappingNameSelected('');
      setItemPreparationMapping(null);
    }
  };
  const handleOpenNewLossesDialog = () => {
    setOpenNewLossesDialog(true);
    setPreparationId('');
  };
  const handleCloseNewLossesDialog = () => {
    setPreparationId('');
    setItemPreparationMappingNameSelected('');
    setItemPreparationMapping(null);
    setOpenNewLossesDialog(false);
    setLossDescription(undefined);
    setDiscardedDescription(undefined);
    setLossSelected('');
    setDiscaredSelected('');
    setPreparedLoss(0);
    setPreparedDiscarded(0);
  };
  const handleItemPreparationMappingSelected = (insumo: ItemPreparationMapping | null) => {
    setItemPreparationMapping(insumo);
    setPreparationId(insumo!!.preparationId);
  };
  const handleItemPreparationMappingNameSelected = (insumo: string) => {
    setItemPreparationMappingNameSelected(insumo);
  };
  const handleSelectedLoss = (event: { target: { value: React.SetStateAction<string> } }) => {
    setLossDescription(
      listTypeDecline.data.filter((element) => element._id === event.target.value)[0],
    );
    setLossSelected(event.target.value);
  };
  const handleSelectedDiscared = (event: { target: { value: React.SetStateAction<string> } }) => {
    setDiscardedDescription(
      listTypeDecline.data.filter((element) => element._id === event.target.value)[0],
    );
    setDiscaredSelected(event.target.value);
  };
  const handlePreparedDiscarded = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPreparedDiscarded(parseInt(event.target.value.toString()));
  };
  const handlePreparedLoss = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPreparedLoss(parseInt(event.target.value.toString()));
  };
  return (
    <LossAndDiscardedPageView
      lossAndDicarded={losses.length > 0 ? losses : lossesAndDiscarded}
      handleOpenNewLossesDialog={handleOpenNewLossesDialog}
      openNewLossesDialog={openNewLossesDialog}
      handleCloseNewLossesDialog={handleCloseNewLossesDialog}
      handleCreatedLosses={handleCreatedLosses}
      handleSelectedDiscared={handleSelectedDiscared}
      handleSelectedLoss={handleSelectedLoss}
      lossSelected={lossSelected}
      discaredSelected={discaredSelected}
      handlePreparedDiscarded={handlePreparedDiscarded}
      handlePreparedLoss={handlePreparedLoss}
      listTypeDecline={listTypeDecline.data}
      handleItemPreparationMappingSelected={handleItemPreparationMappingSelected}
      itemPreparationMappingSelected={itemPreparationMappingSelected}
      handleItemPreparationMappingNameSelected={handleItemPreparationMappingNameSelected}
      itemPreparationMappingNameSelected={itemPreparationMappingNameSelected}
      itemPreparationMapping={itemPreparationMapping.data}
      preparedDiscarded={preparedDiscarded}
    />
  );
};

export default LossAndDiscardedContainer;
