import React from 'react';
import axios from 'axios';
import { ItemPreparationMapping } from '../models/ItemPreparationMapping';

export interface ItemPreparationMappingState {
  data: ItemPreparationMapping[];
  isLoading: boolean;
}

const useItemPreparationMapping = (): ItemPreparationMappingState => {
  const [itemPreparationMapping, setItemPreparationMapping] = React.useState<ItemPreparationMapping[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getItemPreparationMappings = async () => {
      setIsLoading(true);
        const itemPreparationMappings = await axios.get<ItemPreparationMapping[]>(
          `${process.env.REACT_APP_API_URL}/api/v1/loss-and-discarded/item`,
        );
        setItemPreparationMapping(itemPreparationMappings.data);
     
      setIsLoading(false);
    };

    getItemPreparationMappings();
  }, []);

  return {
    data: itemPreparationMapping,
    isLoading,
  };
};

export default useItemPreparationMapping;
