import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Card, Grid, CardContent, CardActionArea, IconButton } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '441px',
  height: '369px',
  bgcolor: 'background.paper',
  p: 8,
};
export interface DialogDataProps {
  openDialog: boolean;
  onClose: () => void;
  title: string;
  content: string;
  response: boolean;
}

export default function SavePreparationModal(props: DialogDataProps) {
  const { openDialog, onClose, title, content, response } = props;
  return (
    <Modal
      open={openDialog}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#00000',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid xs={12} md={6} lg={12}>
          <Typography
            variant="h5"
            color="#052550"
            gutterBottom
            fontFamily="Poppins"
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: {
                xl: 28,
                lg: 26,
                md: 24,
                sm: 18,
                xs: 14,
              },
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid xs={12} md={6} lg={12}>
          <Typography
            color="#232323"
            gutterBottom
            fontFamily="Roboto"
            variant="body1"
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {content}
          </Typography>
        </Grid>
        <Grid xs={12} md={6} lg={12} sx={{ ml: 15 }}>
          {response ? (
            <CheckCircleOutlineRounded
              color="success"
              sx={{
                width: '80px',
                height: '80px',
                top: '45%',
                left: '43%',
              }}
            />
          ) : (
            <CloseIcon
              color="error"
              sx={{
                width: '80px',
                height: '80px',
                top: '45%',
                left: '43%',
              }}
            />
          )}
        </Grid>
        <Grid xs={12} md={6} lg={12}>
          <Card
            sx={{
              width: '329px',
              backgroundColor: '#052550',
              verticalAlign: 'middle',
              textAlign: 'center',
              height: '64px',
              borderRadius: '4px',
              top: '70%',
              position: 'absolute',
              mt: 2,
            }}
          >
            <CardActionArea sx={{ height: '100%' }} onClick={onClose}>
              <CardContent
                sx={{
                  verticalAlign: 'middle',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h5"
                  color="#FFFFFF"
                  gutterBottom
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: {
                      xl: 28,
                      lg: 26,
                      md: 24,
                      sm: 18,
                      xs: 14,
                    },
                  }}
                >
                  <PasswordIcon sx={{ mr: 1 }} />
                  CONTINUAR
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
