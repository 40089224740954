import { Autocomplete, Box, FormControl, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { TaskUser } from '../../shared/models/KitchenTask';

interface kitchenNewRememberViewProps {
  handleTaskDescription: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleSelectedTaskDeadline: (event: { target: { value: React.SetStateAction<string> } }) => void;
  taskUserSelected: TaskUser | null | undefined;
  handleTaskUserSelected: (user: TaskUser | null | undefined) => void;
  taskUserNameSelected: string;
  handleTaskUserNameSelected: (user: string) => void;
  users: TaskUser[];
}

const NewKitchenRemember = (props: kitchenNewRememberViewProps): React.ReactElement => {
  const {
    handleTaskDescription,
    handleSelectedTaskDeadline,
    taskUserSelected,
    handleTaskUserSelected,
    taskUserNameSelected,
    handleTaskUserNameSelected,
    users,
  } = props;
  return (
    <Grid
      container
      sx={{
        width: '100%',
        verticalAlign: 'middle',
      }}
    >
      <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Typography
          color={'#052550'}
          fontFamily="Poppins"
          variant={'h5'}
          sx={{ width: '309px', height: '27px', left: '200px', top: '288px' }}
        >
          Nuevo Recordatorio
        </Typography>
      </Grid>
      <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            width: '600px',
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <Autocomplete
            value={taskUserSelected}
            onChange={(event: any, newValue: TaskUser | null | undefined) => {
              handleTaskUserSelected(newValue);
            }}
            inputValue={taskUserNameSelected}
            onInputChange={(event, newInputValue) => {
              handleTaskUserNameSelected(newInputValue);
            }}
            id="controllable-states-demo"
            options={users}
            autoHighlight
            getOptionLabel={(option) => option.name}
            sx={{ width: 600 }}
            renderOption={(props, option) => (
              <Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Responsable"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            width: '100%',
          }}
        >
          <TextField
            id="datetime-local"
            label="Fecha Límite"
            type="datetime-local"
            onChange={handleSelectedTaskDeadline}
            defaultValue={new Date().toISOString().split('T')[0]}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>

      <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            width: '600px',
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <TextField
            id="outlined-multiline-static"
            label="Descripción"
            multiline
            rows={3}
            onChange={handleTaskDescription}
            placeholder="Ingresa una descripción para esta actividad."
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default NewKitchenRemember;
