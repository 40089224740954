import { Avatar, Grid, Typography } from '@mui/material';
import React from 'react';
import { TaskUser } from '../../shared/models/KitchenTask';
import { getUserImageUrl } from '../../utils/commons';

interface newKitchenResumeViewProps {
  taskCategorySelected: string;
  typeTaskBoardSelected: string;
  taskDeadline: string;
  taskUserSelected: TaskUser | null | undefined;
}

const NewKitchenResume = (props: newKitchenResumeViewProps): React.ReactElement => {
  const { taskCategorySelected, typeTaskBoardSelected, taskDeadline, taskUserSelected } = props;
  const user = localStorage.getItem('ks.user');

  return (
    <Grid
      container
      spacing={1}
      borderRadius={2}
      sx={{
        backgroundColor: '#F1EFEF',
        width: '400px',
        height: '438px',
        mt: 2,
        ml: 2,
        pr: 1,
        verticalAlign: 'middle',
      }}
    >
      <Grid xs={12} md={12} sx={{ display: 'flex', mt: 4, ml: 3 }} item>
        <Typography
          sx={{
            height: '26px',
            width: '336px',
            top: '232px',
            left: '872px',
            fontSize: '26px',
            alignItems: 'center',
          }}
          color="#052550"
          variant={'h5'}
          fontFamily={'Poppins'}
        >
          Resumen
        </Typography>
      </Grid>

      <Grid xs={12} md={12} sx={{ mt: 2, ml: 3 }} item>
        <Grid container>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            Responsable:
          </Typography>
          <Avatar
            sx={{ width: '29.22px', height: '32px' }}
            src={getUserImageUrl(taskUserSelected?.id)}
          ></Avatar>
          <Typography
            sx={{
              ml: 2,
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            {taskUserSelected?.name}
          </Typography>
        </Grid>

        <Grid container sx={{ mt: 1 }}>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            Fecha Límite:
          </Typography>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            {taskDeadline.split('T')[0]}
          </Typography>
        </Grid>

        <Grid container sx={{ mt: 1 }}>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            Estatus:
          </Typography>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            En Espera
          </Typography>
        </Grid>
      </Grid>

      <Grid xs={12} md={12} sx={{ mt: 2, ml: 3 }} item>
        <Grid container>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            Asignada por:
          </Typography>
          <Avatar
            sx={{ width: '29.22px', height: '32px' }}
            src={getUserImageUrl(JSON.parse(user!!).id)}
          ></Avatar>
          <Typography
            sx={{
              ml: 2,
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            {JSON.parse(user!!).name}
          </Typography>
        </Grid>

        <Grid container sx={{ mt: 1 }}>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            Creada el:
          </Typography>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            {new Date().toISOString().split('T')[0]}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12} md={12} sx={{ mt: 2, ml: 3 }} item>
        <Grid container>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            Tipo:
          </Typography>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            {typeTaskBoardSelected}
          </Typography>
        </Grid>

        <Grid container sx={{ mt: 1 }}>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            Categoría:
          </Typography>
          <Typography
            sx={{
              height: '24px',
              width: '117.41px',
              top: '4px',
              left: '0px',
              fontSize: '16px',
              letterSpacing: '0.15px',
            }}
            color="#052550"
            fontFamily={'Roboto'}
          >
            {taskCategorySelected}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewKitchenResume;
