import { Card, CardActionArea, CardContent, IconButton, StyledEngineProvider } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Station } from '../../shared/models/Station';
import PreparationTable from './PreparationTable.view';
import TimeFrame from '../../shared/models/TimeFrame';
import { useNavigate } from 'react-router';
import { PreparationState } from '../../shared/hooks/preparation.hook';
import { Preparation } from '../../shared/models/Preparation';
import { DialogData } from '../../shared/models/DialogData';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import FinishValidatePreparationDialog from '../FinishValidatePreparationDialog';
import SavePreparationModal from '../savePreparationDialog';
import TypeDecline from '../../shared/models/typeDecline';

interface SelectorPreparationViewProps {
  stationId?: string;
  frameTimeId?: string;
  selectedStation: Station;
  selectedTimeFrame: TimeFrame;
  preparations: PreparationState;
  handleShowRecipe: (preparation: Preparation) => void;
  handlePreparation: (preparation: Preparation) => void;
  dialog: DialogData;
  onClickDialog: () => void;
  isMiseEnPlaceComplete: boolean;
  isSaving: Boolean;
  isLoading: Boolean;
  openDialogValidatePreparation: boolean;
  handleCloseValidatePreparationDialog: () => void;
  handlePreparedQuantity: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handlePreparedDiscarded: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handlePreparedLoss: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleFinishPreparation: () => void;
  unit: string;
  listTypeDecline: TypeDecline[];
  handleSelectedLoss: (event: { target: { value: React.SetStateAction<string> } }) => void;
  lossSelected: string;
  handleSelectedDiscared: (event: { target: { value: React.SetStateAction<string> } }) => void;
  discaredSelected: string;
  country: string;
  lossField: boolean;
  discardedField: boolean;
}

const SelectorPreparationView = (props: SelectorPreparationViewProps): React.ReactElement => {
  const navigate = useNavigate();
  const {
    selectedStation,
    preparations,
    handleShowRecipe,
    handlePreparation,
    dialog,
    onClickDialog,
    isMiseEnPlaceComplete,
    isSaving,
    isLoading,
    openDialogValidatePreparation,
    handleCloseValidatePreparationDialog,
    handlePreparedQuantity,
    handlePreparedDiscarded,
    handlePreparedLoss,
    handleFinishPreparation,
    unit,
    listTypeDecline,
    handleSelectedLoss,
    lossSelected,
    handleSelectedDiscared,
    discaredSelected,
    country,
    lossField,
    discardedField,
  } = props;

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100%',
        pb: 2,
      }}
    >
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
        }}
      >
        <Grid container>
          <Grid xs={12} md={6} lg={8}>
            <Typography
              variant="h3"
              color="dark"
              gutterBottom
              sx={{
                verticalAlign: 'middle',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              MISE EN PLACE: {selectedStation.name.toUpperCase()}
              {isMiseEnPlaceComplete && (
                <IconButton color="success">
                  <CheckCircleOutlineRounded sx={{ fontSize: '2rem' }} />
                </IconButton>
              )}
            </Typography>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card
              sx={{
                backgroundColor: 'white',
                verticalAlign: 'middle',
                textAlign: 'center',
                height: '60px',
              }}
            >
              <CardActionArea sx={{ height: '100%' }} onClick={() => navigate('/miseEnPlace')}>
                <CardContent
                  sx={{
                    verticalAlign: 'middle',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    color="dark"
                    gutterBottom
                    sx={{
                      fontSize: {
                        xl: 28,
                        lg: 26,
                        md: 24,
                        sm: 18,
                        xs: 14,
                      },
                    }}
                  >
                    VER ESTACIONES
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
        }}
      >
        <PreparationTable
          preparations={preparations}
          handleShowRecipe={handleShowRecipe}
          handlePreparation={handlePreparation}
          isSaving={isSaving}
          isLoading={isLoading}
        />
      </Grid>
      <StyledEngineProvider injectFirst>
        <SavePreparationModal
          openDialog={dialog.openDialog}
          onClose={onClickDialog}
          title={dialog.title}
          content={dialog.message}
          response={dialog.response}
        />
      </StyledEngineProvider>
      <StyledEngineProvider injectFirst>
        <FinishValidatePreparationDialog
          openDialog={openDialogValidatePreparation}
          handleCloseValidatePreparationDialog={handleCloseValidatePreparationDialog}
          handlePreparedQuantity={handlePreparedQuantity}
          handleFinishPreparation={handleFinishPreparation}
          unit={unit}
          handlePreparedDiscarded={handlePreparedDiscarded}
          handlePreparedLoss={handlePreparedLoss}
          listTypeDecline={listTypeDecline}
          handleSelectedLoss={handleSelectedLoss}
          lossSelected={lossSelected}
          handleSelectedDiscared={handleSelectedDiscared}
          discaredSelected={discaredSelected}
          country={country}
          lossField={lossField}
          discardedField={discardedField}
        />
      </StyledEngineProvider>
    </Container>
  );
};

export default SelectorPreparationView;
