import React from 'react';
import { useNavigate } from 'react-router';
import { useMount } from 'react-use';
import useStation from '../../../shared/hooks/station.hook';
import useTimeFrame from '../../../shared/hooks/timeFrame.hook';
import useToolbarTitle from '../../../shared/hooks/useToolbarTitle';
import { Station } from '../../../shared/models/Station';
import TimeFrame from '../../../shared/models/TimeFrame';
import { KitchenUser } from '../../../shared/models/UserInformation';
import SelectorPageView from './selectorStation.view';

const SelectorStationContainer = (): React.ReactElement => {
  const navigate = useNavigate();
  const [selectedFrame, setSelectedFrame] = React.useState<TimeFrame>();

  const timeFramesData = useTimeFrame();
  const stationsData = useStation(selectedFrame || timeFramesData.selectedFrame);

  const handleStationSelect = (station: Station, timeFrame?: TimeFrame) => {
    localStorage.setItem('ks.selectedStation', JSON.stringify(station));
    localStorage.setItem('ks.selectedtimeFrame', JSON.stringify(timeFrame));
    navigate(`/preparation/${station._id}/${timeFrame?._id}`);
  };

  const handleTimeFrameSelect = (timeFrame: TimeFrame) => {
    setSelectedFrame(timeFrame);
  };

  const kitchen: KitchenUser = JSON.parse(localStorage.getItem('ks.kitchen')!!);

  const toolbarTitle = useToolbarTitle();

  useMount(() => {
    toolbarTitle.setTitle(kitchen?.name, 'ESTACIONES');
  });

  return (
    <SelectorPageView
      stationsData={stationsData.data}
      isLoading={stationsData.isLoading}
      onStationClick={handleStationSelect}
      onTimeFrameClick={handleTimeFrameSelect}
      timeFrames={timeFramesData.data}
      previousTimeFrame={selectedFrame || timeFramesData.selectedFrame}
    />
  );
};

export default SelectorStationContainer;
