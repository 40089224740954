import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import useCities from '../hooks/cities.hook';
import useKitchenByCitie from '../hooks/kitchenByCities.hook';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '40%',
  bgcolor: 'background.paper',
  p: 8,
};

const KitchenSelector = (): React.ReactElement => {
  let { navigateTo, userId, userToken } = useParams();
  
  const [citySelected, setCitySelected] = React.useState('');
  const [kitchenSelected, setKitchenIdSelected] = React.useState('');

  const navigate = useNavigate();
  const cities = useCities().data;
  const kitchenByCity = useKitchenByCitie(citySelected);

  const handleChangeCitySelected = (event: { target: { value: React.SetStateAction<string> } }) => {
    setCitySelected(event.target.value);    
  };

  const handleKitchenSelected = (event: { target: { value: React.SetStateAction<string> } }) => {
    setKitchenIdSelected(event.target.value);    
    navigate(`/${navigateTo}/${userId}/${userToken}/${event.target.value}`)
  };  

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <Grid xs={12} item={true}>
          <Typography
            variant="h5"
            color="#052550"
            gutterBottom
            fontFamily="Poppins"
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: {
                xl: 28,
                lg: 26,
                md: 24,
                sm: 18,
                xs: 14,
              },
            }}
          >
            Seleccionar Cocina
          </Typography>

          <Grid
            container
            sx={{
              width: '100%',
              verticalAlign: 'middle',
              mt: '20px',
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Ciudad"
                value={citySelected}
                onChange={handleChangeCitySelected}
              >
                {cities.map((element) => (
                  <MenuItem key={element._id} value={element._id}>{element.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{
              width: '100%',
              verticalAlign: 'middle',
              mt: '20px',
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="cocina">Cocina</InputLabel>
              <Select
                id="cocina"
                label="Cocina"
                value={kitchenSelected}
                onChange={handleKitchenSelected}
              >
                {kitchenByCity.data.map((element) => (
                  <MenuItem key={element._id} value={element.kitchenId}>{element.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>
      </Grid>
    </Modal>
  );
};

export default KitchenSelector;
