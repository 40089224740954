import React from 'react';
import axios from 'axios';
import TimeFrame from '../models/TimeFrame';
import { KitchenUser } from '../models/UserInformation';
import { PredictionData } from '../models/Prediction';

export interface StationState {
  data: PredictionData[];
  isLoading: boolean;
}

const usePrediction = (frame?: TimeFrame, dayOfWeek?: number): StationState => {
  const kitchen: KitchenUser = JSON.parse(localStorage.getItem('ks.kitchen')!!);

  const [stations, setStations] = React.useState<PredictionData[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getStations = async () => {
      setIsLoading(true);
      if (frame) {
        const stations = await axios.get<PredictionData[]>(
          `${process.env.REACT_APP_API_URL}/api/v1/prediction/list/${frame._id}/${kitchen.country}/${dayOfWeek}`,
        );
        setStations(stations.data);
      }
      setIsLoading(false);
    };

    getStations();
  }, [dayOfWeek, frame, kitchen.country]);

  return {
    data: stations,
    isLoading,
  };
};

export default usePrediction;
