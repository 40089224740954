import { Avatar, Button } from '@mui/material';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { KitchenTask } from '../../shared/models/KitchenTask';
import { getUserImageUrl } from '../../utils/commons';
import {
  CardHeader,
  TaskContainer,
  CardContent,
  FlexDiv,
  DesciptionLabel,
  DescriptionDiv,
} from '../styledComponents';

const Task = (props: any): React.ReactElement => {
  const { task, onNextState } = props;

  const getButtonName = (task: KitchenTask) => {
    if (task.type === 'RECORDATORIO') {
      return 'AVANZAR';
    }
    if (task.status === 'in_process') {
      return 'TERMINAR';
    }
    return 'REALIZAR';
  };

  return (
    <Draggable draggableId={task._id} index={props.index}>
      {(provided, snapshot) => (
        <TaskContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <CardHeader color={props.color}>{task.type}</CardHeader>
          <CardContent>
            <DescriptionDiv>
              {task.status === 'complete' && (
                <Avatar sx={{ width: '42px' }} src={getUserImageUrl(task.assignedTo?.id)}></Avatar>
              )}
              <DesciptionLabel>{task.description}</DesciptionLabel>
            </DescriptionDiv>

            {task.status !== 'complete' && (
              <FlexDiv>
                <Avatar sx={{ width: '42px' }} src={getUserImageUrl(task.assignedTo?.id)}></Avatar>
                <Button
                  sx={{
                    backgroundColor: props.color,
                    width: '100%',
                    color: 'white',
                    fontSize: '18px',
                    fontWeight: '500ƒ',
                    ml: '24px',
                  }}
                  onClick={() => onNextState(task, props.index)}
                >
                  {getButtonName(task)}
                </Button>
              </FlexDiv>
            )}
          </CardContent>
        </TaskContainer>
      )}
    </Draggable>
  );
};

export default Task;
