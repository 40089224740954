import React, { ChangeEventHandler } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, TextField } from '@mui/material';
import { Prediction } from '../../../shared/models/Prediction';

interface PreparationTableProps {
  preparation: Prediction[] | undefined;
  handleChange: (preparationId: string) => ChangeEventHandler<HTMLInputElement>;
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F9FAFC',
    color: 'primary.main',
    height: '60px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const PreparationTable = (props: PreparationTableProps): React.ReactElement => {
  const { preparation, handleChange } = props;

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>INSUMO</StyledTableCell>
                <StyledTableCell align="center">CANTIDAD SUGERIDA</StyledTableCell>
                <StyledTableCell align="center">CANTIDAD VALIDADA</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {preparation?.map((item, index) => (
                <TableRow key={item.preparationId}>
                  <StyledTableCell component="th" scope="row">
                    {item.description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.suggestedQuantity} {item.unit}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TextField
                      id="outlined-required"
                      label="Ingresar Cantidad"
                      variant="outlined"
                      defaultValue={item.validateQuantity}
                      onChange={handleChange(item.preparationId.toString())}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PreparationTable;
