import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const stations = 6;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F9FAFC',
    color: 'primary.main',
    height: '48px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const PreparationSkeleton = (): React.ReactElement => (
  <>
    <Table sx={{ minWidth: 700 }} size="small">
      <TableHead>
        <TableRow>
          <StyledTableCell>Insumo</StyledTableCell>
          <StyledTableCell align="center">Cantidad sugerida</StyledTableCell>
          <StyledTableCell align="center">Unidad</StyledTableCell>
          <StyledTableCell align="center">Terminado</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(stations)].map((item: number) => (
          <TableRow key={item}>
            <TableCell>
              <Skeleton animation="wave" variant="rectangular" width={'100%'} height={40} />
            </TableCell>
            <TableCell>
              <Skeleton animation="wave" variant="rectangular" width={'100%'} height={40} />
            </TableCell>
            <TableCell>
              <Skeleton animation="wave" variant="rectangular" width={'100%'} height={40} />
            </TableCell>
            <TableCell>
              <Skeleton animation="wave" variant="rectangular" width={'100%'} height={40} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>
);

export default PreparationSkeleton;
