import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Card, Grid, CardContent, CardActionArea, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PasswordIcon from '@mui/icons-material/Password';
import useTaskCategory from '../../shared/hooks/taskCategory.hook';
import NewKitchenRemember from './NewKitchenRemember';
import NewKitchenTask from './NewKitchenTask';
import NewKitchenResume from './NewKitchenResume';
import UseUserByName from '../../shared/hooks/userByName.hook';
import { KitchenTask, TaskUser } from '../../shared/models/KitchenTask';
import saveTaskBoard from '../../shared/hooks/taskBoardNew';

export interface DialogDataProps {
  openDialog: boolean;
  handleCloseDialog: () => void;
  getTaskData: (myUser: boolean) => void;
}

export default function NewKitchenTaskDialog(props: DialogDataProps) {
  const { openDialog, handleCloseDialog, getTaskData } = props;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1152px',
    height: '670px',
    bgcolor: 'background.paper',
    p: 8,
  };
  const typeTaskBoard = ['RECORDATORIO', 'TAREA'];
  const [typeTaskBoardSelected, setTypeTaskBoardSelected] = React.useState(typeTaskBoard[1]);
  const taskCategory = useTaskCategory();
  const [taskCategorySelected, setTaskCategorySelected] = React.useState('');
  const [taskReviewSelected, setTaskReviewSelected] = React.useState(false);
  const [taskDeadline, setTaskDeadline] = React.useState('');
  const [taskDsecription, setTaskDsecription] = React.useState('');
  const [taskUserSelected, setTaskUserSelected] = React.useState<TaskUser | null | undefined>();
  const [taskUserNameSelected, setTaskUserNameSelected] = React.useState('');
  const users = UseUserByName(taskUserNameSelected);
  const kitchen = localStorage.getItem('ks.kitchen');
  const user = localStorage.getItem('ks.user');

  const handleSelectedTaskCategory = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setTaskCategorySelected(event.target.value);
  };
  const handleSelectedTaskReview = (event: { target: { value: React.SetStateAction<string> } }) => {
    event.target.value === 'SI' ? setTaskReviewSelected(true) : setTaskReviewSelected(false);
  };
  const handleTypeTaskBoardSelected = (type: string) => {
    handleRestoreSettingsTask();
    setTypeTaskBoardSelected(type);
  };
  const handleSelectedTaskDeadline = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setTaskDeadline(event.target.value);
  };
  const handleTaskDescription = (event: { target: { value: React.SetStateAction<string> } }) => {
    setTaskDsecription(event.target.value);
  };
  const handleTaskUserSelected = (user: TaskUser | null | undefined) => {
    setTaskUserSelected(user);
  };
  const handleTaskUserNameSelected = (user: string) => {
    setTaskUserNameSelected(user);
  };
  const handleNewTaskOrRemember = async () => {
    const createdBy: TaskUser = {
      id: JSON.parse(user!!).id,
      document: JSON.parse(user!!).document,
      name: JSON.parse(user!!).name,
    };
    const dataTaskBoardSave: KitchenTask = {
      createdBy: createdBy,
      createdAt: new Date(),
      assignedTo: taskUserSelected!!,
      type: typeTaskBoardSelected,
      status: 'waiting',
      description: taskDsecription,
      kitchenId: JSON.parse(kitchen!!).kitchenId,
      category: taskCategory.data.filter((element) => element.name === taskCategorySelected)[0],
      limitDate: new Date(taskDeadline),
      revisionRequired: taskReviewSelected,
      comments: [],
    };
    const dataRememberBoardSave: KitchenTask = {
      createdBy: createdBy,
      assignedTo: taskUserSelected!!,
      createdAt: new Date(),
      type: typeTaskBoardSelected,
      status: 'waiting',
      description: taskDsecription,
      kitchenId: JSON.parse(kitchen!!).kitchenId,
      comments: [],
    };
    const response = await saveTaskBoard(
      typeTaskBoardSelected === 'TAREA' ? dataTaskBoardSave : dataRememberBoardSave,
    );
    if (response) {
      handleRestoreSettingsTask();
      handleCloseDialog();
      getTaskData(false);
    }
  };
  const handleRestoreSettingsTask = () => {
    setTaskCategorySelected('');
    setTaskReviewSelected(false);
    setTaskDeadline('');
    setTaskDsecription('');
    setTaskUserSelected(null);
    setTaskUserNameSelected('');
  };

  return (
    <Modal
      open={openDialog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        handleRestoreSettingsTask();
        handleCloseDialog();
      }}
    >
      <Grid container sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleRestoreSettingsTask();
            handleCloseDialog();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#00000',
          }}
        >
          <CloseIcon />
        </IconButton>

        <Grid
          container
          spacing={1}
          borderRadius={2}
          sx={{
            backgroundColor: '#FFFFFF',
            width: '60%',
            mt: 2,
            pr: 1,
            verticalAlign: 'middle',
          }}
        >
          {typeTaskBoard.map((element) => (
            <Grid item xs={12 / typeTaskBoard.length}>
              <Card
                sx={{
                  backgroundColor: typeTaskBoardSelected === element ? '#F1EFEF' : '#FFFFF',
                  verticalAlign: 'middle',
                  textAlign: 'center',
                  height: '56px',
                }}
              >
                <CardActionArea
                  sx={{ height: '100%' }}
                  onClick={() => handleTypeTaskBoardSelected(element)}
                >
                  <CardContent
                    sx={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      height: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      color="dark"
                      gutterBottom
                      align="center"
                      sx={{
                        margin: 0,
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: {
                          xl: 28,
                          lg: 26,
                          md: 24,
                          sm: 18,
                          xs: 14,
                        },
                      }}
                    >
                      {element}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
          <Grid xs={12} md={12} ml={1} item>
            {typeTaskBoardSelected === 'TAREA' ? (
              <NewKitchenTask
                taskCategory={taskCategory.data}
                taskCategorySelected={taskCategorySelected}
                handleSelectedTaskCategory={handleSelectedTaskCategory}
                handleSelectedTaskDeadline={handleSelectedTaskDeadline}
                handleTaskDescription={handleTaskDescription}
                taskUserSelected={taskUserSelected}
                handleTaskUserSelected={handleTaskUserSelected}
                taskUserNameSelected={taskUserNameSelected}
                handleTaskUserNameSelected={handleTaskUserNameSelected}
                users={users.data}
                handleSelectedTaskReview={handleSelectedTaskReview}
              />
            ) : (
              <NewKitchenRemember
                handleTaskDescription={handleTaskDescription}
                handleSelectedTaskDeadline={handleSelectedTaskDeadline}
                taskUserSelected={taskUserSelected}
                handleTaskUserSelected={handleTaskUserSelected}
                taskUserNameSelected={taskUserNameSelected}
                handleTaskUserNameSelected={handleTaskUserNameSelected}
                users={users.data}
              />
            )}
          </Grid>
        </Grid>
        <NewKitchenResume
          taskCategorySelected={taskCategorySelected}
          typeTaskBoardSelected={typeTaskBoardSelected}
          taskDeadline={taskDeadline}
          taskUserSelected={taskUserSelected}
        />
        <Grid xs={12} md={12} mt={2} item>
          <Card
            sx={{
              width: '100%',
              backgroundColor: '#052550',
              verticalAlign: 'middle',
              textAlign: 'center',
              height: '56px',
              mt: 2,
              borderRadius: '4px',
              top: '586px',
            }}
          >
            <CardActionArea sx={{ height: '100%' }} onClick={() => handleNewTaskOrRemember()}>
              <CardContent
                sx={{
                  verticalAlign: 'middle',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h5"
                  color="#FFFFFF"
                  gutterBottom
                  sx={{
                    margin: 0,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: {
                      xl: 28,
                      lg: 26,
                      md: 24,
                      sm: 18,
                      xs: 14,
                    },
                  }}
                >
                  {' '}
                  <PasswordIcon sx={{ mr: 1 }} />
                  REGISTRAR ACTIVIDAD
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
