import React from 'react';
import axios from 'axios';
import { City } from '../models/Station';

export interface CityState {
  data: City[];
  isLoading: boolean;
}

const useCities = (): CityState => {
  const [cities, setCities] = React.useState<City[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getStations = async () => {
      setIsLoading(true);
      const cities = await axios.get<City[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/kitchenos/unsecure/cities`,
      );
      setCities(cities.data);
      setIsLoading(false);
    };

    getStations();
  }, []);

  return {
    data: cities,
    isLoading,
  };
};

export default useCities;
