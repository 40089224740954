import axios from 'axios';
import { KitchenTask, ResponseKitchenTask } from '../models/KitchenTask';

const saveTaskBoard = async (dataTaskBoardSave: KitchenTask): Promise<ResponseKitchenTask> => {
  return await axios
    .post<ResponseKitchenTask>(
      `${process.env.REACT_APP_API_URL}/api/v1/task-manager`,
      dataTaskBoardSave,
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return { isSuccessful: false, message: error.message };
    });
};

export default saveTaskBoard;
