import { Avatar, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Typography from '@mui/material/Typography';
import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { StationData, Station } from '../../../shared/models/Station';
import TimeFrame from '../../../shared/models/TimeFrame';

interface KitchenCardViewProps {
  stationData: StationData;
  timeFrame?: TimeFrame;
  onClick: (station: Station, timeFrame?: TimeFrame) => void;
}

const StationCard = (props: KitchenCardViewProps): React.ReactElement => {
  const { stationData, timeFrame, onClick } = props;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 5,
    width: '95%',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: stationData.isMiseEnPlaceComplete === true ? '#8DC63F' : '#FF9800',
    },
  }));
  const porcentagePreparations = (): number => {
    return stationData.totalPreparations === 0
      ? 0
      : Math.floor((stationData.completePreparations * 100) / stationData.totalPreparations);
  };
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        height: '170px',
        backgroundImage: `url(https://kitchen-staff-assets.s3.amazonaws.com/stations/${stationData.station.slug}.jpg)`,
      }}
    >
      <CardActionArea
        sx={{ height: '100%' }}
        onClick={() => onClick(stationData.station, timeFrame)}
      >
        <CardContent sx={{ verticalAlign: 'top', height: '100%' }}>
          <Typography
            gutterBottom
            variant="h4"
            color={'white'}
            component="div"
            sx={{ verticalAlign: 'top', textAlign: 'left' }}
          >
            {stationData.station.name}
          </Typography>
          <Box sx={{ mt: 9, alignItems: 'center' }}>
            <BorderLinearProgress variant="determinate" value={porcentagePreparations()} />
          </Box>
        </CardContent>
      </CardActionArea>
      <Avatar sx={{ height: 60, width: 60, mt: 12, right: 20, background: '#E5E5E5' }}>
        {stationData.isMiseEnPlaceComplete ? (
          <TaskAltIcon sx={{ height: 45, width: 53, color: '#8DC63F' }} />
        ) : (
          <Typography variant="h6" color={'black'}>
            {porcentagePreparations()}%
          </Typography>
        )}
      </Avatar>
    </Card>
  );
};

export default StationCard;
