import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid } from '@mui/material';

const stations = 6;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F9FAFC',
    color: 'primary.main',
    height: '48px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ControlPreparationTableSkeleton = (): React.ReactElement => (
  <>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Table sx={{ minWidth: 700 }} size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">INSTRUCCIONES</StyledTableCell>
              <StyledTableCell>PREPARACIONES</StyledTableCell>
              <StyledTableCell align="center">APERTURA - 11:00 AM</StyledTableCell>
              <StyledTableCell align="center">11:00 AM - 05:00 PM</StyledTableCell>
              <StyledTableCell align="center">5:00 PM - CIERRE</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(stations)].map((item: number) => (
              <TableRow key={item}>
                <StyledTableCell component="th" scope="row">
                  <Skeleton animation="wave" variant="rectangular" width={'100%'} height={30} />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Skeleton animation="wave" variant="rectangular" width={'100%'} height={30} />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Skeleton animation="wave" variant="rectangular" width={'100%'} height={30} />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Skeleton animation="wave" variant="rectangular" width={'100%'} height={30} />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Skeleton animation="wave" variant="rectangular" width={'100%'} height={30} />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  </>
);

export default ControlPreparationTableSkeleton;
