import { Card, CardActionArea, CardContent, IconButton } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Station, StationData } from '../../../shared/models/Station';
import StationCard from './stationCard.view';
import TimeFrame from '../../../shared/models/TimeFrame';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import StationsSkeleton from '../../skeleton/StationsSkeleton';

interface SelectorPageViewProps {
  stationsData: StationData[];
  isLoading: Boolean;
  onStationClick: (station: Station, timeFrame?: TimeFrame) => void;
  onTimeFrameClick: (timeFrame: TimeFrame) => void;
  timeFrames: TimeFrame[];
  previousTimeFrame?: TimeFrame;
}

const SelectorPageView = (props: SelectorPageViewProps): React.ReactElement => {
  const {
    onTimeFrameClick,
    onStationClick,
    stationsData,
    isLoading,
    timeFrames,
    previousTimeFrame,
  } = props;

  const isSelected = (selectedTimeFrame: TimeFrame) => {
    return previousTimeFrame && previousTimeFrame._id === selectedTimeFrame._id;
  };

  const isComplete = (): boolean => {
    return stationsData.filter((station) => !station.isMiseEnPlaceComplete).length === 0;
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100%',
        pb: 2,
      }}
    >
      <Grid
        container
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
          verticalAlign: 'middle',
        }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item>
            <Typography color={'#000000'} fontFamily="Poppins" variant={'h1'}>
              Estaciones Disponibles
            </Typography>
            <Typography color={'#000000'} fontFamily="Poppins" variant={'body1'} mt={1}>
              Selecciona la estación para realizar Mise en Place
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          backgroundColor: '#FFFFFF',
          width: '100%',
          mt: 2,
          mb: 3,
          pb: 1,
          pr: 1,
          verticalAlign: 'middle',
        }}
      >
        {timeFrames.map((frame) => (
          <Grid item xs={12 / timeFrames.length}>
            <Card
              sx={{
                backgroundColor: isSelected(frame) ? '#F1EFEF' : '#FFFFFF',
                verticalAlign: 'middle',
                textAlign: 'center',
                height: '56px',
              }}
            >
              <CardActionArea sx={{ height: '100%' }} onClick={() => onTimeFrameClick(frame)}>
                <CardContent
                  sx={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    color="dark"
                    gutterBottom
                    align="center"
                    sx={{
                      margin: 0,
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: {
                        xl: 28,
                        lg: 26,
                        md: 24,
                        sm: 18,
                        xs: 14,
                      },
                    }}
                  >
                    {frame.description}
                    {isSelected(frame) && isComplete() && (
                      <IconButton color="success">
                        <CheckCircleOutlineRounded sx={{ fontSize: '2rem' }} />
                      </IconButton>
                    )}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {isLoading ? (
        <Grid container spacing={2}>
          <StationsSkeleton />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {stationsData?.map((stationData) => (
            <Grid key={stationData.station._id} xs={12} md={6} lg={6} item>
              <StationCard
                stationData={stationData}
                timeFrame={previousTimeFrame}
                onClick={onStationClick}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default SelectorPageView;
