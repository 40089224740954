import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  StyledEngineProvider,
  Typography,
} from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import React from 'react';
import { LossAndDiscarded } from '../../shared/models/LossAndDiscarded';
import LossAndDicardedTable from './lossAndDiscardedTable';
import NewLossesDialog from '../NewLossesDialog';
import TypeDecline from '../../shared/models/typeDecline';
import { ItemPreparationMapping } from '../../shared/models/ItemPreparationMapping';

interface LossAndDiscardedViewProps {
  lossAndDicarded: LossAndDiscarded[];
  handleOpenNewLossesDialog: () => void;
  openNewLossesDialog: boolean;
  handleCloseNewLossesDialog: () => void;
  handleCreatedLosses: () => void;
  handleSelectedLoss: (event: { target: { value: React.SetStateAction<string> } }) => void;
  lossSelected: string;
  handleSelectedDiscared: (event: { target: { value: React.SetStateAction<string> } }) => void;
  discaredSelected: string;
  listTypeDecline: TypeDecline[];
  handlePreparedDiscarded: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handlePreparedLoss: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleItemPreparationMappingSelected: (insumo: ItemPreparationMapping | null) => void;
  itemPreparationMappingSelected: ItemPreparationMapping | null;
  handleItemPreparationMappingNameSelected: (insumo: string) => void;
  itemPreparationMapping: ItemPreparationMapping[];
  itemPreparationMappingNameSelected: string;
  preparedDiscarded: number;
}

const LossAndDiscardedPageView = (props: LossAndDiscardedViewProps): React.ReactElement => {
  const {
    lossAndDicarded,
    handleOpenNewLossesDialog,
    openNewLossesDialog,
    handleCloseNewLossesDialog,
    handleCreatedLosses,
    handleSelectedLoss,
    lossSelected,
    handleSelectedDiscared,
    discaredSelected,
    listTypeDecline,
    handlePreparedDiscarded,
    handlePreparedLoss,
    handleItemPreparationMappingSelected,
    itemPreparationMappingSelected,
    itemPreparationMapping,
    handleItemPreparationMappingNameSelected,
    itemPreparationMappingNameSelected,
    preparedDiscarded,
  } = props;

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100%',
        pb: 2,
      }}
    >
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
        }}
      >
        <Grid container>
          <Grid xs={12} md={6} lg={8}>
            <Typography color={'#000000'} fontFamily="Poppins" variant={'h1'}>
              Control de Mermas y Bajas
            </Typography>
            <Typography color={'#000000'} fontFamily="Roboto" variant={'body1'} mt={1}>
              Reporta la cantidad de mermas y bajas en tu cocina.
            </Typography>
          </Grid>
          <Grid xs={12} md={6} lg={4} mt={2}>
            <Card
              sx={{
                backgroundColor: '#FFFFFF',
                verticalAlign: 'middle',
                textAlign: 'center',
                height: '60px',
              }}
            >
              <CardActionArea sx={{ height: '100%' }} onClick={() => handleOpenNewLossesDialog()}>
                <CardContent
                  sx={{
                    verticalAlign: 'middle',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      margin: 0,
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: {
                        xl: 28,
                        lg: 26,
                        md: 24,
                        sm: 18,
                        xs: 14,
                      },
                    }}
                  >
                    <PasswordIcon sx={{ mr: 1 }} />
                    NUEVA MERMA O BAJA
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
          verticalAlign: 'middle',
        }}
      >
        {' '}
        <LossAndDicardedTable lossAndDicarded={lossAndDicarded} />
      </Grid>
      <StyledEngineProvider injectFirst>
        <NewLossesDialog
          openDialog={openNewLossesDialog}
          handleCloseDialog={handleCloseNewLossesDialog}
          handleCreatedLosses={handleCreatedLosses}
          handleSelectedDiscared={handleSelectedDiscared}
          handleSelectedLoss={handleSelectedLoss}
          lossSelected={lossSelected}
          discaredSelected={discaredSelected}
          handlePreparedDiscarded={handlePreparedDiscarded}
          handlePreparedLoss={handlePreparedLoss}
          listTypeDecline={listTypeDecline}
          handleItemPreparationMappingSelected={handleItemPreparationMappingSelected}
          itemPreparationMappingSelected={itemPreparationMappingSelected}
          handleItemPreparationMappingNameSelected={handleItemPreparationMappingNameSelected}
          itemPreparationMapping={itemPreparationMapping}
          itemPreparationMappingNameSelected={itemPreparationMappingNameSelected}
          preparedDiscarded={preparedDiscarded}
        />
      </StyledEngineProvider>
    </Container>
  );
};

export default LossAndDiscardedPageView;
