import axios from 'axios';
import { Preparation, ResponsePreparationUpdate } from '../models/Preparation';

const preparationUpdate = async (preparation: Preparation): Promise<ResponsePreparationUpdate> => {
  return await axios
    .post<ResponsePreparationUpdate>(
      `${process.env.REACT_APP_API_URL}/api/v1/timeframe/preparation/update/`,
      { preparation: preparation },
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return { isSuccessful: false, message: error.message };
    });
};

export default preparationUpdate;
