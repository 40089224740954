import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import DisplayTemplateContext from '../templates/DisplayNew/contexts/displayTemplate.context';
import AccountMenu from './AccountMenu';
import ClientClock from './ClientClock';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = (): React.ReactElement => {
  const displayTemplateContext = React.useContext(DisplayTemplateContext);

  return (
    <AppBar
      position="fixed"
      color={process.env.REACT_APP_IS_PRODUCTION === 'true' ? 'primary' : 'secondary'}
    >
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <Box sx={{ mr: 5 }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => displayTemplateContext.setDrawerOpen(true)}
              edge="start"
              sx={{
                marginRight: 5,
                ...(displayTemplateContext.drawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon htmlColor="white" />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={() => displayTemplateContext.setDrawerOpen(false)}
              edge="start"
              sx={{
                marginRight: 5,
                ...(!displayTemplateContext.drawerOpen && { display: 'none' }),
              }}
            >
              <CloseIcon htmlColor="white" />
            </IconButton>
          </Box>

          <Box display="flex" gap={0.5} alignItems="center">
            <img
              src="https://images-kos.s3.amazonaws.com/logo_Foodology/logo_foodology_blanco.png"
              alt="Foodology logo"
              height={26}
            />
          </Box>
        </Box>

        {/* Center area */}
        <Box sx={{ textTransform: 'uppercase', flex: 4, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {process.env.REACT_APP_IS_PRODUCTION === 'false' && '🧬 PRUEBAS '}
            {displayTemplateContext.toolbarTitle}
          </Typography>
        </Box>

        {/* Right area */}
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
          <Box
            sx={{ display: 'table', textAlign: 'center', marginLeft: '32px', marginRight: '32px' }}
          >
            <ClientClock />
          </Box>
          <Box>
            <AccountMenu />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
