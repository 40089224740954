import axios from 'axios';
import { Prediction } from '../models/Prediction';
import { ResponsePreparationUpdate } from '../models/Preparation';

const stationPredictionUpdate = async (
  predictions: Prediction[] | undefined,
): Promise<ResponsePreparationUpdate> => {
  return await axios
    .post<ResponsePreparationUpdate>(
      `${process.env.REACT_APP_API_URL}/api/v1/station/prediction/update/`,
      { predictions: predictions },
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return { isSuccessful: false, message: error.message };
    });
};

export default stationPredictionUpdate;
