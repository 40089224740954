import { KitchenTask } from './KitchenTask';

interface TaskColumn {
  id: string;
  title: string;
  color: string;
  taskIds: string[];
}

class TaskBoardData {
  tasks: KitchenTask[] = [];
  columnOrder: string[] = ['waiting', 'in_process', 'in_review', 'complete'];
  columns: TaskColumn[] = [
    { id: 'waiting', title: 'EN ESPERA', color: '#A7A7A7', taskIds: [] },
    { id: 'in_process', title: 'EN PROCESO', color: '#FF9800', taskIds: [] },
    { id: 'in_review', title: 'EN REVISIÓN', color: '#7B61FF', taskIds: [] },
    { id: 'complete', title: 'TERMINADAS', color: '#8DC63F', taskIds: [] },
  ];
}

export { TaskBoardData , TaskColumn};
