import {
  Card,
  CardActionArea,
  CardContent,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  StyledEngineProvider,
} from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TimeFrame from '../../shared/models/TimeFrame';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { City, KitchenByCity, Station } from '../../shared/models/Station';
import { Preparation } from '../../shared/models/Preparation';
import ControlPreparationTableSkeleton from '../skeleton/ControlPreparationTableSkeleton';
import ControlPreparationsTable from './parametrizePreparationsTable.view';
import NewPreparationModal from '../NewPreparationDialog';
import SavePreparationModal from '../savePreparationDialog';
import { DialogData } from '../../shared/models/DialogData';

interface SelectorControlPreparationsPageViewProps {
  timeFrames: TimeFrame[];
  cities: City[];
  citySelected: string;
  kitchenByCity: KitchenByCity[];
  kitchenIdSelected: string;
  stations: Station[];
  stationSelected: string;
  preparations: Preparation[];
  isLoading: Boolean;
  showTable: boolean;
  handleChangeCitySelected: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleChangeKitchen: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleChangeStation: (event: { target: { value: React.SetStateAction<string> } }) => void;
  updatePreparationTimeFrame: (timeFrameId: string, preparation: Preparation) => void;
  handleChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchTerm: string;
  openDialogNewPreparation: boolean;
  handleOpenDialog: (element: boolean) => void;
  handleUnitPreparationDialog: (event: { target: { value: React.SetStateAction<string> } }) => void;
  unitPreparation: string;
  handleNamePreparation: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleTimeFramePreparation: (timeFrameId: string) => void;
  handleAddNewPreparation: () => void;
  requiredFieldNamePreparation: boolean;
  requiredFieldUnit: boolean;
  handleCloseDialogSavePreparation: () => void;
  filteredPreparationsDialog: Preparation[];
  handleEditPreparation: (namePreparation: string) => void;
  openDialogFinishMiseEnPlace: DialogData;
}

const SelectorControlPreparationsPageView = (
  props: SelectorControlPreparationsPageViewProps,
): React.ReactElement => {
  const {
    timeFrames,
    cities,
    citySelected,
    kitchenByCity,
    kitchenIdSelected,
    stations,
    stationSelected,
    preparations,
    isLoading,
    showTable,
    handleChangeKitchen,
    handleChangeCitySelected,
    handleChangeStation,
    updatePreparationTimeFrame,
    handleChangeSearch,
    searchTerm,
    openDialogNewPreparation,
    handleOpenDialog,
    handleUnitPreparationDialog,
    unitPreparation,
    handleNamePreparation,
    handleTimeFramePreparation,
    handleAddNewPreparation,
    requiredFieldNamePreparation,
    requiredFieldUnit,
    handleCloseDialogSavePreparation,
    filteredPreparationsDialog,
    handleEditPreparation,
    openDialogFinishMiseEnPlace,
  } = props;

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100%',
        pb: 2,
      }}
    >
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
        }}
      >
        <Grid container>
          <Grid xs={12} md={6} lg={8}>
            <Typography color={'#000000'} fontFamily="Poppins" variant={'h1'}>
              Control de Preparaciones
            </Typography>
            <Typography color={'#000000'} fontFamily="Roboto" variant={'body1'} mt={1}>
              Activa o desactiva las preparaciones en los diferentes horarios de Mise en Plase
            </Typography>
          </Grid>
          <Grid xs={12} md={6} lg={4} mt={2}>
            <Card
              sx={{
                backgroundColor: '#FFFFFF',
                verticalAlign: 'middle',
                textAlign: 'center',
                height: '60px',
              }}
            >
              <CardActionArea
                sx={{ height: '100%' }}
                disabled={!showTable}
                onClick={() => handleOpenDialog(true)}
              >
                <CardContent
                  sx={{
                    verticalAlign: 'middle',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    color={showTable ? '#052550' : '#A7A7A7'}
                    gutterBottom
                    sx={{
                      margin: 0,
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: {
                        xl: 28,
                        lg: 26,
                        md: 24,
                        sm: 18,
                        xs: 14,
                      },
                    }}
                  >
                    <PasswordIcon sx={{ mr: 1 }} />
                    NUEVA PREPARACIÓN
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
          verticalAlign: 'middle',
        }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} md={6} lg={3} item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Ciudad"
                value={citySelected}
                onChange={handleChangeCitySelected}
              >
                {cities.map((element) => (
                  <MenuItem value={element._id}>{element.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={6} lg={3} item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Cocina</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Cocina"
                value={kitchenIdSelected}
                onChange={handleChangeKitchen}
              >
                {kitchenByCity.map((element) => (
                  <MenuItem value={element._id}>{element.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={6} lg={3} item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estación</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Estación"
                value={stationSelected}
                onChange={handleChangeStation}
              >
                {stations.map((element) => (
                  <MenuItem value={element._id}>{element.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={6} lg={3} item>
            <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Buscar preparación"
                value={searchTerm}
                onChange={handleChangeSearch}
                disabled={!showTable}
              />
              <IconButton sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={2} mt={2}>
          <Grid xs={12} item>
            <ControlPreparationTableSkeleton />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} mt={2}>
          <Grid xs={12} item>
            <ControlPreparationsTable
              preparations={preparations}
              timeFrames={timeFrames}
              showTable={showTable}
              handleChangeUpdatePreparationTimeFrame={updatePreparationTimeFrame}
            />
          </Grid>
        </Grid>
      )}
      <StyledEngineProvider injectFirst>
        <NewPreparationModal
          openDialog={openDialogNewPreparation}
          onClose={() => handleOpenDialog(false)}
          timeFrames={timeFrames}
          handleUnitPreparationDialog={handleUnitPreparationDialog}
          unitPreparation={unitPreparation}
          handleNamePreparation={handleNamePreparation}
          handleTimeFramePreparation={handleTimeFramePreparation}
          handleAddNewPreparation={handleAddNewPreparation}
          requiredFieldNamePreparation={requiredFieldNamePreparation}
          requiredFieldUnit={requiredFieldUnit}
          preparations={filteredPreparationsDialog}
          handleEditPreparation={handleEditPreparation}
        />
      </StyledEngineProvider>
      <StyledEngineProvider injectFirst>
        <SavePreparationModal
          openDialog={openDialogFinishMiseEnPlace.openDialog}
          onClose={handleCloseDialogSavePreparation}
          title={openDialogFinishMiseEnPlace.title}
          content={openDialogFinishMiseEnPlace.message}
          response={openDialogFinishMiseEnPlace.response}
        />
      </StyledEngineProvider>
    </Container>
  );
};

export default SelectorControlPreparationsPageView;
