import {
  Accordion,
  AccordionSummary,
  Card,
  CardActionArea,
  CardContent,
  StyledEngineProvider,
} from '@mui/material';
import PreparationTable from './selectorStationTable.view';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TimeFrame from '../../../shared/models/TimeFrame';
import React, { ChangeEventHandler } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PasswordIcon from '@mui/icons-material/Password';
import { DialogData } from '../../../shared/models/DialogData';
import SuggestedSkeleton from '../../skeleton/SuggestedSkeleton';
import { PredictionData } from '../../../shared/models/Prediction';
import SavePreparationModal from '../../savePreparationDialog';

interface SelectorPageViewProps {
  stationsData: PredictionData[];
  isLoading: Boolean;
  handleDayOfWeekSelect: (dayOfWeek: number) => void;
  onTimeFrameClick: (timeFrame: TimeFrame) => void;
  timeFrames: TimeFrame[];
  previousTimeFrame?: TimeFrame;
  handleChange: (predictionId: string | undefined) => ChangeEventHandler<HTMLInputElement>;
  dayOfWeek: number;
  handleFinishPrediction: () => void;
  onClickDialog: () => void;
  dialog: DialogData;
}

const SelectorPageView = (props: SelectorPageViewProps): React.ReactElement => {
  const {
    onTimeFrameClick,
    handleDayOfWeekSelect,
    handleChange,
    handleFinishPrediction,
    onClickDialog,
    dialog,
    stationsData,
    isLoading,
    timeFrames,
    previousTimeFrame,
    dayOfWeek,
  } = props;

  const dayOfWeekName = [
    { name: 'LUN', dayOfWeek: 1 },
    { name: 'MAR', dayOfWeek: 2 },
    { name: 'MIE', dayOfWeek: 3 },
    { name: 'JUE', dayOfWeek: 4 },
    { name: 'VIE', dayOfWeek: 5 },
    { name: 'SAB', dayOfWeek: 6 },
    { name: 'DOM', dayOfWeek: 0 },
  ];

  const isSelected = (selectedTimeFrame: TimeFrame) => {
    return previousTimeFrame && previousTimeFrame._id === selectedTimeFrame._id;
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100%',
        pb: 2,
      }}
    >
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
        }}
      >
        <Grid container>
          <Grid xs={12} md={6} lg={8}>
            <Typography color={'#000000'} variant={'h1'} fontFamily={'Poppins'}>
              Validación Mise en Place
            </Typography>
            <Typography color={'#000000'} variant={'body1'} fontFamily={'Roboto'} mt={1}>
              Valida las cantidades sugeridas para el Mise en Place.{' '}
            </Typography>
          </Grid>
          <Grid xs={12} md={6} lg={4} mt={2}>
            <Card
              sx={{
                backgroundColor: '#052550',
                verticalAlign: 'middle',
                textAlign: 'center',
                height: '60px',
              }}
            >
              <CardActionArea sx={{ height: '100%' }} onClick={() => handleFinishPrediction()}>
                <CardContent
                  sx={{
                    verticalAlign: 'middle',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    color="#FFFFFF"
                    gutterBottom
                    sx={{
                      margin: 0,
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: {
                        xl: 28,
                        lg: 26,
                        md: 24,
                        sm: 18,
                        xs: 14,
                      },
                    }}
                  >
                    <PasswordIcon sx={{ mr: 1 }} />
                    CONFIRMAR CANTIDADES
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
          verticalAlign: 'middle',
        }}
      >
        {dayOfWeekName.map((dow) => (
          <Grid item xs={12 / dayOfWeekName.length}>
            <Card
              sx={{
                backgroundColor: dayOfWeek === dow.dayOfWeek ? '#F1EFEF' : '#FFFFF',
                verticalAlign: 'middle',
                textAlign: 'center',
                height: '60px',
              }}
            >
              <CardActionArea
                sx={{ height: '100%' }}
                onClick={() => handleDayOfWeekSelect(dow.dayOfWeek)}
              >
                <CardContent
                  sx={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    color="dark"
                    gutterBottom
                    align="center"
                    sx={{
                      margin: 0,
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: {
                        xl: 28,
                        lg: 26,
                        md: 24,
                        sm: 18,
                        xs: 14,
                      },
                    }}
                  >
                    {dow.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          backgroundColor: '#FFFFFF',
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
          verticalAlign: 'middle',
        }}
      >
        {timeFrames.map((frame) => (
          <Grid item xs={12 / timeFrames.length}>
            <Card
              sx={{
                backgroundColor: isSelected(frame) ? '#F1EFEF' : '#FFFFF',
                verticalAlign: 'middle',
                textAlign: 'center',
                height: '56px',
              }}
            >
              <CardActionArea sx={{ height: '100%' }} onClick={() => onTimeFrameClick(frame)}>
                <CardContent
                  sx={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    color="dark"
                    gutterBottom
                    align="center"
                    sx={{
                      margin: 0,
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: {
                        xl: 28,
                        lg: 26,
                        md: 24,
                        sm: 18,
                        xs: 14,
                      },
                    }}
                  >
                    {frame.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {isLoading ? (
        <SuggestedSkeleton />
      ) : (
        <Grid container spacing={2} mt={2}>
          {stationsData.map((stationData) => (
            <Grid xs={12} item>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: 'dark' }} />}
                  aria-controls="panel1a-content"
                  id={stationData.station._id}
                  sx={{ bgcolor: '#F1EFEF' }}
                >
                  <Typography sx={{ color: 'dark' }} component={'h5'}>
                    {stationData.station.name}
                  </Typography>
                </AccordionSummary>
                <PreparationTable
                  preparation={stationData.predictions}
                  handleChange={handleChange}
                />
              </Accordion>
            </Grid>
          ))}
        </Grid>
      )}
      <StyledEngineProvider injectFirst>
        <SavePreparationModal
          openDialog={dialog.openDialog}
          onClose={onClickDialog}
          title={dialog.title}
          content={dialog.message}
          response={dialog.response}
        />
      </StyledEngineProvider>
    </Container>
  );
};

export default SelectorPageView;
