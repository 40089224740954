import axios from 'axios';
import { ResponseMiseEnPlace } from '../models/MiseEnPlace';

const updateMiseEnPlaceDetail = async (
  stationId: String,
  timeFrameId: String,
): Promise<ResponseMiseEnPlace> => {
  return await axios
    .post<ResponseMiseEnPlace>(
      `${process.env.REACT_APP_API_URL}/api/v1/miseenplace/onComplete/${timeFrameId}/${stationId}`,
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return { isSuccessful: false, message: error.message };
    });
};

export default updateMiseEnPlaceDetail;
