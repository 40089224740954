import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { List, ListItem } from '@mui/material';

const stations = 6;

const SuggestedSkeleton = (): React.ReactElement => (
  <>
    <Grid xs={12} item>
      <List>
        {[...Array(stations)].map((item: number) => (
          <ListItem key={item}>
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={48} />
          </ListItem>
        ))}
      </List>
    </Grid>
  </>
);

export default SuggestedSkeleton;
