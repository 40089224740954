import { AlertColor, SnackbarOrigin } from '@mui/material';
import React from 'react';
import { useAudio } from 'react-use';
import textToSpeech from '../../../../utils/textToSpeech';

type OnBack = (() => void) | null;
type SetOnBackAction = (onBackAction?: OnBack) => void;
type ToolbarTitle = string | null;
type SetToolbarTitle = (toolbarTitle: ToolbarTitle) => void;
type SearchText = string | undefined;
type SetSearchText = (searchText: string) => void;

interface Alert {
  show: boolean;
  title?: string | null;
  message?: string | null;
  duration?: number | null;
  severity?: AlertColor;
  position?: SnackbarOrigin;
}

interface DisplayTemplateContextState {
  onBackAction?: OnBack;
  setOnBackAction?: SetOnBackAction;
  toolbarTitle?: ToolbarTitle;
  setToolbarTitle?: SetToolbarTitle;
  alert: Alert;
  showAlert: (
    title?: string | null,
    message?: string | null,
    duration?: number | null,
    audioOn?: boolean | null,
    severity?: AlertColor | null,
    position?: SnackbarOrigin | null,
  ) => void;
  hideAlert?: () => void;
  searchText?: SearchText;
  setSearchText?: SetSearchText;
  isPassStation?: boolean;
  setIsPassStation?: (isPassStation: boolean) => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
}

const initialState: DisplayTemplateContextState = {
  alert: { show: false },
  showAlert: () => {},
  drawerOpen: false,
  setDrawerOpen: () => {},
};

const DisplayTemplateContext = React.createContext(initialState);

interface DisplayTemplateProviderProps {
  children: React.ReactElement;
}

export const DisplayTemplateProvider = (
  props: DisplayTemplateProviderProps,
): React.ReactElement => {
  const { children } = props;

  const [onBack, setOnback] = React.useState<OnBack>();
  const [toolbarTitle, setToolbarTitle] = React.useState<ToolbarTitle | null>();
  const [alert, setAlert] = React.useState<Alert>({ show: false });
  const [searchText, setSearchText] = React.useState<SearchText>();
  const [isPassStation, setIsPassStation] = React.useState<boolean>();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const [audio, , controls] = useAudio({
    src: 'https://kitchen-os-assets.s3.amazonaws.com/audios/new-order.mp3',
  });

  const setOnBackAction = (onBackAction?: OnBack) => {
    setOnback(() => onBackAction);
  };

  const showAlert = (
    title?: string | null,
    message?: string | null,
    duration?: number | null,
    audioOn?: boolean | null,
    severity?: AlertColor | null,
    position?: SnackbarOrigin | null,
  ) => {
    if (audioOn && title) {
      controls.seek(0);
      controls.play();
      textToSpeech(title, 600);
    }
    setAlert({
      show: true,
      title,
      message,
      duration,
      severity: severity ?? undefined,
      position: position ?? undefined,
    });
  };

  const hideAlert = () => {
    setAlert({ show: false });
  };

  return (
    <DisplayTemplateContext.Provider
      value={{
        onBackAction: onBack,
        setOnBackAction,
        toolbarTitle,
        setToolbarTitle,
        alert,
        showAlert,
        hideAlert,
        searchText,
        setSearchText,
        isPassStation,
        setIsPassStation,
        drawerOpen,
        setDrawerOpen,
      }}
    >
      {children}
      {audio}
    </DisplayTemplateContext.Provider>
  );
};

export default DisplayTemplateContext;
