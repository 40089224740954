import React from 'react';
import axios from 'axios';
import { KitchenByCity } from '../models/Station';

export interface KitchenState {
  data: KitchenByCity[];
  isLoading: boolean;
}

const useKitchenByCity = (cityId: string): KitchenState => {
  const [kitcheByCitie, setKitcheByCitie] = React.useState<KitchenByCity[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getKitchens = async () => {
      setIsLoading(true);
      if (cityId) {
        const kitcheByCitie = await axios.get<KitchenByCity[]>(
          `${process.env.REACT_APP_API_URL}/api/v1/kitchenos/unsecure/kitchen/city/${cityId}`,
        );
        setKitcheByCitie(kitcheByCitie.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    getKitchens();
  }, [cityId]);

  return {
    data: kitcheByCitie,
    isLoading,
  };
};

export default useKitchenByCity;
