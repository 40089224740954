import React from 'react';
import axios from 'axios';
import TimeFrame from '../models/TimeFrame';

export interface TimeFrameState {
  data: TimeFrame[];
  selectedFrame?: TimeFrame;
  isLoading: boolean;
}

const useTimeFrame = (): TimeFrameState => {
  const [timeFrames, setTimeFrames] = React.useState<TimeFrame[]>([]);
  const [selectedFrame, setSelectedFrame] = React.useState<TimeFrame>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getTimeFrames = async () => {
      setIsLoading(true);
      const timeFrames = await axios.get<TimeFrame[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/timeframe/day/${new Date().getDay()}`,
      );
      const hour = new Date().getHours();
      const hourFrames = timeFrames.data.filter(
        (frame) => hour >= frame.startHour && hour <= frame.endHour,
      );
      setSelectedFrame(hourFrames[hourFrames.length - 1] || hourFrames[0]);
      setTimeFrames(timeFrames.data);
      setIsLoading(false);
    };

    getTimeFrames();
  }, []);

  return {
    data: timeFrames,
    selectedFrame: selectedFrame,
    isLoading,
  };
};

export default useTimeFrame;
