import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Card,
  Grid,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  CardActionArea,
  IconButton,
  Button,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import TimeFrame from '../shared/models/TimeFrame';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Preparation } from '../shared/models/Preparation';

export interface DialogDataProps {
  openDialog: boolean;
  onClose: () => void;
  timeFrames: TimeFrame[];
  handleUnitPreparationDialog: (event: { target: { value: React.SetStateAction<string> } }) => void;
  unitPreparation: string;
  handleNamePreparation: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleTimeFramePreparation: (timeFrameId: string) => void;
  handleAddNewPreparation: () => void;
  requiredFieldNamePreparation: boolean;
  requiredFieldUnit: boolean;
  preparations: Preparation[];
  handleEditPreparation: (namePreparation: string) => void;
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1EFEF',
    height: '48px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function NewPreparationModal(props: DialogDataProps) {
  const {
    openDialog,
    onClose,
    timeFrames,
    handleUnitPreparationDialog,
    unitPreparation,
    handleNamePreparation,
    handleTimeFramePreparation,
    handleAddNewPreparation,
    requiredFieldNamePreparation,
    requiredFieldUnit,
    preparations,
    handleEditPreparation,
  } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '970px',
    height: preparations.length < 4 && preparations.length !== 0 ? '650px' : '500px',
    bgcolor: 'background.paper',
    p: 8,
  };
  return (
    <Modal
      open={openDialog}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#00000',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid xs={12} md={6} lg={12}>
          <Typography
            variant="h5"
            color="#052550"
            gutterBottom
            fontFamily="Poppins"
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: {
                xl: 28,
                lg: 26,
                md: 24,
                sm: 18,
                xs: 14,
              },
            }}
          >
            Nueva Preparación
          </Typography>
          <Typography
            color="#232323"
            gutterBottom
            fontFamily="Roboto"
            fontSize={16}
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            Ingresa una nueva preparación a la estación seleccionada
          </Typography>
          <Grid
            container
            sx={{
              width: '100%',
              verticalAlign: 'middle',
            }}
          >
            <Grid container spacing={5}>
              <Grid xs={12} md={6} lg={6} item>
                <FormControl fullWidth sx={{ mt: 4, width: '100%' }}>
                  {requiredFieldNamePreparation ? (
                    <TextField
                      error
                      id="outlined-error-helper-text"
                      label="Ingresa el nombre de la prearación"
                      helperText="Por favor Ingresa el nombre de la prearación."
                      onChange={handleNamePreparation}
                    />
                  ) : (
                    <TextField
                      id="outlined-required"
                      label="Ingresa el nombre de la prearación"
                      variant="outlined"
                      onChange={handleNamePreparation}
                    ></TextField>
                  )}
                </FormControl>
              </Grid>
              <Grid xs={12} md={6} lg={6} item>
                <FormControl fullWidth sx={{ mt: 4, width: '100%' }}>
                  <InputLabel id="demo-simple-select-label">Unidad</InputLabel>
                  <Select
                    error={requiredFieldUnit}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Unidad"
                    value={unitPreparation}
                    onChange={handleUnitPreparationDialog}
                  >
                    <MenuItem value="gr">gr</MenuItem>
                    <MenuItem value="unidad">unidad</MenuItem>
                    <MenuItem value="porciones">porciones</MenuItem>
                    <MenuItem value="Sachets">Sachets</MenuItem>
                    <MenuItem value="ml">ml</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {preparations.length < 4 && preparations.length !== 0 ? (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table sx={{ minWidth: 700 }} size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" width={'80%'}>
                      PREPARACIÓN
                    </StyledTableCell>
                    <StyledTableCell align="center">ACCIONES</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {preparations.map((item) => (
                    <TableRow>
                      <StyledTableCell>{item.description}</StyledTableCell>
                      <StyledTableCell align="center">
                        {' '}
                        <Button
                          onClick={() => handleEditPreparation(item.description || '')}
                          variant="outlined"
                        >
                          EDITAR
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            ''
          )}

          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table sx={{ minWidth: 700 }} size="small">
              <TableHead>
                <TableRow>
                  {timeFrames.map((item) => (
                    <StyledTableCell align="center">{item.description}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center">
                    <Checkbox
                      onChange={() => handleTimeFramePreparation(timeFrames[0]._id || '')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      onChange={() => handleTimeFramePreparation(timeFrames[1]._id || '')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      onChange={() => handleTimeFramePreparation(timeFrames[2]._id || '')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Card
            sx={{
              width: '100%',
              backgroundColor: '#052550',
              verticalAlign: 'middle',
              textAlign: 'center',
              height: '56px',
              mt: 4,
              borderRadius: '4px',
              top: '586px',
            }}
          >
            <CardActionArea sx={{ height: '100%' }} onClick={() => handleAddNewPreparation()}>
              <CardContent
                sx={{
                  verticalAlign: 'middle',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h5"
                  color="#FFFFFF"
                  gutterBottom
                  sx={{
                    margin: 0,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: {
                      xl: 28,
                      lg: 26,
                      md: 24,
                      sm: 18,
                      xs: 14,
                    },
                  }}
                >
                  AGREGAR PREPARACIÓN
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
