import React from 'react';
import axios from 'axios';

import { PreparationData, PreparationResponse } from '../models/Preparation';
import { useMount } from 'react-use';

export interface PreparationState {
  data: PreparationData[];
  isAllComplete: boolean;
  isLoading: boolean;
  isMiseEnPlaceComplete: boolean;
}

const usePreparation = (station_id?: string, timeFrame_id?: string): PreparationState => {
  const [preparations, setPreparations] = React.useState<PreparationData[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isAllComplete, setisAllComplete] = React.useState<boolean>(false);
  const [isMiseEnPlaceComplete, setIsMiseEnPlaceComplete] = React.useState<boolean>(false);

  useMount(() => {
    const getPreparations = async () => {
      setIsLoading(true);
      if (station_id && timeFrame_id) {
        const preparationResponse = await axios.get<PreparationResponse>(
          `${process.env.REACT_APP_API_URL}/api/v1/preparation/station/${station_id}/timeFrame/${timeFrame_id}`,
        );
        const preparationsData = preparationResponse.data.preparations;
        setPreparations(preparationsData);
        
        const _prepatarions = preparationsData.filter((object) => object.isPreparationComplete === true);
        setisAllComplete(_prepatarions.length === preparationsData.length);
        setIsMiseEnPlaceComplete(preparationResponse.data.isMiseEnPlaceComplete)
      }
      setIsLoading(false);
    };

    getPreparations();
  });

  return {
    data: preparations,
    isAllComplete: isAllComplete,
    isLoading,
    isMiseEnPlaceComplete
  };
};

export default usePreparation;
