import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import Routes from './routes';
import Theme from './config/theme';

const App = (): React.ReactElement => (
  <ThemeProvider theme={Theme}>
    <CssBaseline />
    <Routes />
  </ThemeProvider>
);

export default App;
