import axios from 'axios';
import { DataMiseEnPlaceDetailSave, MiseEnPlaceDetail } from '../models/MiseEnPlaceDetail';

const saveMiseEnPlaceDetail = async (
  dataMiseEnPlaceDetailSave: DataMiseEnPlaceDetailSave
): Promise<boolean> => {
  if (dataMiseEnPlaceDetailSave) {
    return await axios
      .post<MiseEnPlaceDetail>(
        `${process.env.REACT_APP_API_URL}/api/v1/miseenplace/detail`,
        dataMiseEnPlaceDetailSave,
      )
      .then((result) => {
        return result?.data ? true : false;
      })
      .catch((error) => {
        return false;
      });
  }
  return await false;
};

export default saveMiseEnPlaceDetail;
