import React from 'react';
import axios from 'axios';
import { Preparation } from '../models/Preparation';

export interface StationByCountryState {
  data: Preparation[];
  isLoading: boolean;
}

const usePreparationByStation = (stationId: string, kitchenId: string): StationByCountryState => {
  const [preparations, setPreparations] = React.useState<Preparation[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getPreparations = async () => {
      setIsLoading(true);
      if (stationId && kitchenId) {
        const preparations = await axios.get<Preparation[]>(
          `${process.env.REACT_APP_API_URL}/api/v1/preparations/list/${stationId}/${kitchenId}`,
        );
        preparations.data.sort((a, b) => b.timeFrameIds.length - a.timeFrameIds.length);
        setPreparations(preparations.data);
      } else {
        setPreparations([]);
      }
      setIsLoading(false);
    };

    getPreparations();
  }, [stationId, kitchenId]);

  return {
    data: preparations,
    isLoading,
  };
};

export default usePreparationByStation;
