import React from 'react';
import axios from 'axios';
import { LossAndDiscarded } from '../models/LossAndDiscarded';

export interface LoosAndDiscardedState {
  data: LossAndDiscarded[];
  isLoading: boolean;
}
const useLossAndDiscarded = (): LoosAndDiscardedState => {
  const [loossAndDiscarded, setLoossAndDiscarded] = React.useState<LossAndDiscarded[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getLooseAndDiscarded = async () => {
      setIsLoading(true);

      const looses = await axios.get<LossAndDiscarded[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/loss-and-discarded/kitchen`,
      );
      setLoossAndDiscarded(looses.data);
      setIsLoading(false);
    };

    getLooseAndDiscarded();
  }, []);

  return {
    data: loossAndDiscarded,
    isLoading,
  };
};

export default useLossAndDiscarded;
