import React from 'react';
import axios from 'axios';
import TypeDecline from '../models/typeDecline';

export interface TypeDeclineState {
  data: TypeDecline[];
  isLoading: boolean;
}

const useTypeDecline = (): TypeDeclineState => {
  const [typeDeclines, setTypeDecloines] = React.useState<TypeDecline[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getTypeDecline = async () => {
      setIsLoading(true);
      const typeDecline = await axios.get<TypeDecline[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/miseenplace/typeDecline`,
      );
      setTypeDecloines(typeDecline.data);
      setIsLoading(false);
    };

    getTypeDecline();
  }, []);

  return {
    data: typeDeclines,
    isLoading
  };
};

export default useTypeDecline;
