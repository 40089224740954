import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { TaskCategory, TaskUser } from '../../shared/models/KitchenTask';

interface kitchenNewHomeworkViewProps {
  taskCategory: TaskCategory[];
  taskCategorySelected: string;
  handleSelectedTaskCategory: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleSelectedTaskDeadline: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleTaskDescription: (event: { target: { value: React.SetStateAction<string> } }) => void;
  taskUserSelected: TaskUser | null | undefined;
  handleTaskUserSelected: (user: TaskUser | null | undefined) => void;
  taskUserNameSelected: string;
  handleTaskUserNameSelected: (user: string) => void;
  users: TaskUser[];
  handleSelectedTaskReview: (event: { target: { value: React.SetStateAction<string> } }) => void;
}

const NewKitchenTask = (props: kitchenNewHomeworkViewProps): React.ReactElement => {
  const {
    taskCategory,
    taskCategorySelected,
    handleSelectedTaskCategory,
    handleSelectedTaskDeadline,
    handleTaskDescription,
    taskUserSelected,
    handleTaskUserSelected,
    taskUserNameSelected,
    handleTaskUserNameSelected,
    handleSelectedTaskReview,
    users,
  } = props;

  return (
    <Grid
      container
      sx={{
        width: '100%',
        verticalAlign: 'middle',
      }}
    >
      <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Typography
          color={'#052550'}
          fontFamily="Poppins"
          variant={'h5'}
          sx={{ width: '309px', height: '27px', left: '200px', top: '288px' }}
        >
          Nueva Tarea
        </Typography>
      </Grid>
      <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            width: '50%',
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <Autocomplete
            value={taskUserSelected}
            onChange={(event: any, newValue: TaskUser | null | undefined) => {
              handleTaskUserSelected(newValue);
            }}
            inputValue={taskUserNameSelected}
            onInputChange={(event, newInputValue) => {
              handleTaskUserNameSelected(newInputValue);
            }}
            id="controllable-states-demo"
            options={users}
            autoHighlight
            getOptionLabel={(option) => option.name}
            sx={{ width: 600 }}
            renderOption={(props, option) => (
              <Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Responsable"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            width: '180px',
            height: '62.61px',
          }}
        >
          <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Categoría"
            value={taskCategorySelected}
            onChange={handleSelectedTaskCategory}
          >
            {taskCategory.map((element) => (
              <MenuItem value={element.name}>{element.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            ml: 4,
            width: '240px',
            height: '61.3px',
            alignContent: 'center',
          }}
        >
          <TextField
            id="datetime-local"
            label="Fecha Límite"
            type="datetime-local"
            onChange={handleSelectedTaskDeadline}
            defaultValue={new Date().toISOString().split('T')[0]}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            ml: 4,
            width: '116px',
            height: '62.61px',
          }}
        >
          <InputLabel id="demo-simple-select-label">Revsión</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Recurrente"
            onChange={handleSelectedTaskReview}
          >
            <MenuItem value={'SI'}>{'SI'}</MenuItem>
            <MenuItem value={'NO'}>{'NO'}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            width: '600px',
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <TextField
            id="outlined-multiline-static"
            label="Descripción"
            multiline
            rows={3}
            onChange={handleTaskDescription}
            placeholder="Ingresa una descripción para esta actividad."
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default NewKitchenTask;
