import Column from './Column';
import { DragDropContext } from 'react-beautiful-dnd';
import React from 'react';
import styled from 'styled-components';
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  StyledEngineProvider,
  Typography,
} from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import PageTitle from '../../shared/components/PageTitle';
import { TaskBoardData, TaskColumn } from '../../shared/models/TaskBoardData';
import { KitchenTask } from '../../shared/models/KitchenTask';
import { getKitchenTask, updateTaskStatus } from '../../shared/services/taskManager.services';
import NewKitchenTaskDialog from '../newTask/NewKitchenTaskDialog';
import { useNavigate } from 'react-router';

const BoardContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TaskBoard = (): React.ReactElement => {
  const navigate = useNavigate();
  const [state, setState] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [openNewTaskBoardDialog, setOpenNewTaskBoardDialog] = React.useState<boolean>(false);
  const [filterTask, setFilterTask] = React.useState<boolean>(false);

  const module = "kitchen-staff";

  const filterTasks = [
    { name: 'Mis Actividades', myUser: true },
    { name: 'Actividades Globales', myUser: false }
  ];

  const handleFilter = (filter:any) => {
    setFilterTask(filter.myUser)
    getTaskData(filter.myUser);
  }


  const getTaskData = async (myUser:boolean) => {
    setIsLoading(true);
    const kitchenTask = await getKitchenTask(myUser);
    let data = new TaskBoardData();
    data.tasks = kitchenTask.data;
    data.tasks.forEach((task: KitchenTask) => {
      data.columns.find((col: TaskColumn) => col.id === task.status)?.taskIds.push(task._id!!);
    });
    setState(data);
    setIsLoading(false);
  };

  React.useEffect(() => {
    getTaskData(false);
  }, []);

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;
    if (
      !destination ||
      (destination.droppableId === source.droppableId && destination.index === source.index)
    ) {
      return;
    }
    const start = state.columns.find((col: TaskColumn) => col.id === source.droppableId);
    const finish = state.columns.find((col: TaskColumn) => col.id === destination.droppableId);
    const startIndex = state.columns.findIndex((col: TaskColumn) => col.id === source.droppableId);
    const finishIndex = state.columns.findIndex(
      (col: TaskColumn) => col.id === destination.droppableId,
    );

    let newColumns = state.columns;
    let newTasks = state.tasks;
    if (start === finish) {
      start.taskIds.splice(source.index, 1);
      start.taskIds.splice(destination.index, 0, draggableId);
      newColumns[startIndex] = start;
    } else {
      start.taskIds.splice(source.index, 1);
      finish.taskIds.splice(destination.index, 0, draggableId);
      newColumns[startIndex] = start;
      newColumns[finishIndex] = finish;
      newTasks = updateTask(state.tasks, draggableId, finish.id);
    }

    const newState = {
      ...state,
      tasks: newTasks,
      columns: newColumns,
    };
    setState(newState);
  };

  const onNextState = (task: KitchenTask, index: number) => {
    const start = state.columns.find((col: TaskColumn) => col.id === task.status);
    const startIndex = state.columns.findIndex((col: TaskColumn) => col.id === task.status);
    let moveStatusCount = 1;
    if (!task.revisionRequired && task.status === 'in_process') {
      moveStatusCount++;
    }

    const destinationColumn = state.columns[startIndex + moveStatusCount];

    let newColumns = state.columns;

    start.taskIds.splice(index, 1);
    destinationColumn.taskIds.splice(0, 0, task._id);

    newColumns[startIndex] = start;
    newColumns[startIndex + moveStatusCount] = destinationColumn;

    const newTasks = updateTask(state.tasks, task._id!!, destinationColumn.id);

    const newState = {
      ...state,
      tasks: newTasks,
      columns: newColumns,
    };
    if (task.status === "in_process" && task.category?.callToAction && task.category?.module) {
      if (task.category.module === module) {
        navigate(`/${task.category.callToAction}`);
      }
    } 
    setState(newState);
  };
  const handleCloseNewTaskBoardDialog = () => {
    setOpenNewTaskBoardDialog(false);
  };
  const handleOpenNewTaskBoardDialog = () => {
    setOpenNewTaskBoardDialog(true);
  };

  const updateTask = (
    tasks: KitchenTask[],
    actualTaskId: string,
    newStatus: string,
  ): KitchenTask[] => {
    updateTaskStatus(actualTaskId, newStatus);
    return tasks.map((newTask: KitchenTask) => {
      if (newTask._id === actualTaskId) {
        newTask.status = newStatus;
      }
      return newTask;
    });
  };

  return isLoading ? (
    <CircularProgress />
  ) : (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100%',
        mt: 2,
        pb: 1,
        pr: 1,
      }}
    >
      <Grid container item>
        <PageTitle
          title="Tareas de Cocina"
          description="Gestiona y mira el seguimiento de las tareas a realizar en tu cocina."
        ></PageTitle>
        <Grid xs={12} md={6} lg={4} mt={2} item>
          <Card
            sx={{
              backgroundColor: '#FFFFFF',
              verticalAlign: 'middle',
              textAlign: 'center',
              height: '60px',
            }}
          >
            <CardActionArea sx={{ height: '100%' }} onClick={() => handleOpenNewTaskBoardDialog()}>
              <CardContent
                sx={{
                  verticalAlign: 'middle',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  color={'#052550'}
                  gutterBottom
                  sx={{
                    margin: 0,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: {
                      xl: 28,
                      lg: 26,
                      md: 24,
                      sm: 18,
                      xs: 14,
                    },
                  }}
                >
                  <PasswordIcon sx={{ mr: 1 }} />
                  NUEVA ACTIVIDAD
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        borderRadius={2}
        sx={{
          width: '100%',
          mt: 2,
          pb: 1,
          pr: 1,
          verticalAlign: 'middle',
        }}
      >
        {filterTasks.map((filter) => (
          <Grid item xs={12 / filterTasks.length}>
            <Card
              sx={{
                backgroundColor: filter.myUser === filterTask ? '#F1EFEF' : '#FFFFF',
                verticalAlign: 'middle',
                textAlign: 'center',
                height: '60px',
              }}
            >
              <CardActionArea
                sx={{ height: '100%' }}
                onClick={() => handleFilter(filter)}
              >
                <CardContent
                  sx={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    color="dark"
                    gutterBottom
                    align="center"
                    sx={{
                      margin: 0,
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: {
                        xl: 28,
                        lg: 26,
                        md: 24,
                        sm: 18,
                        xs: 14,
                      },
                    }}
                  >
                    {filter.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <BoardContainer>
          {state?.columnOrder?.map((columnId: string) => {
            const column = state.columns.find((col: TaskColumn) => col.id === columnId);
            const tasks = column.taskIds.map((taskId: any) =>
              state.tasks.find((task: any) => task._id === taskId),
            );
            return (
              <Column
                key={column?.id || ''}
                column={column}
                tasks={tasks}
                onNextState={onNextState}
              />
            );
          })}
        </BoardContainer>
      </DragDropContext>
      <StyledEngineProvider injectFirst>
        <NewKitchenTaskDialog
          openDialog={openNewTaskBoardDialog}
          handleCloseDialog={handleCloseNewTaskBoardDialog}
          getTaskData={getTaskData}
        />
      </StyledEngineProvider>
    </Container>
  );
};

export default TaskBoard;
