import amplitude from 'amplitude-js';

export enum AmplitudeEvent {
  SaveQuantity = 'Guardar Cantidades',
  FinishPreparation = 'Finalizar preparación',
  UpdatePreparation = 'Actualizar franja preparación',  
  EnterMiseEnPlace = 'Ingreso Mise en Place',  
}

const logEvent = (event: string, eventProperties?: Record<string, unknown>): void => {
  amplitude.getInstance().logEvent(event, eventProperties);
};

const setUserProperties = (userProperties: Record<string, unknown>): void => {
  amplitude.getInstance().setUserProperties(userProperties);
};

const setUserId = (userId: string): void => {
  amplitude.getInstance().setUserId(userId);
};

const init = (): void => {
  amplitude.getInstance().init(`${process.env.REACT_APP_AMPLITUD_API_KEY}`);
};

export { init, logEvent, setUserId, setUserProperties };
