import React from 'react';
import axios from 'axios';
import { StationData } from '../models/Station';
import TimeFrame from '../models/TimeFrame';
import { KitchenUser } from '../models/UserInformation';

export interface StationState {
  data: StationData[];
  isLoading: boolean;
}

const useStation = (frame?: TimeFrame): StationState => {

  const kitchen: KitchenUser = JSON.parse(localStorage.getItem('ks.kitchen')!!);
  
  const [stations, setStations] = React.useState<StationData[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getStations = async () => {
      setIsLoading(true);
      if (frame) {
        const stations = await axios.get<StationData[]>(
          `${process.env.REACT_APP_API_URL}/api/v1/station/list/${frame._id}/${kitchen.country}`,
        );
        setStations(stations.data);
      }
      setIsLoading(false);
    };

    getStations();
  }, [frame, kitchen.country]);

  return {
    data: stations,
    isLoading,
  };
};

export default useStation;
