import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Card,
  Grid,
  CardContent,
  FormControl,
  CardActionArea,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TypeDecline from '../shared/models/typeDecline';
import { ItemPreparationMapping } from '../shared/models/ItemPreparationMapping';

export interface DialogDataProps {
  openDialog: boolean;
  handleCloseDialog: () => void;
  handleCreatedLosses: () => void;
  handleSelectedLoss: (event: { target: { value: React.SetStateAction<string> } }) => void;
  lossSelected: string;
  handleSelectedDiscared: (event: { target: { value: React.SetStateAction<string> } }) => void;
  discaredSelected: string;
  listTypeDecline: TypeDecline[];
  handlePreparedDiscarded: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handlePreparedLoss: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleItemPreparationMappingSelected: (insumo: ItemPreparationMapping | null) => void;
  itemPreparationMappingSelected: ItemPreparationMapping | null;
  handleItemPreparationMappingNameSelected: (insumo: string) => void;
  itemPreparationMapping: ItemPreparationMapping[];
  itemPreparationMappingNameSelected: string;
  preparedDiscarded: number;
}

export default function NewLossesDialog(props: DialogDataProps) {
  const {
    openDialog,
    handleCloseDialog,
    handleCreatedLosses,
    handleSelectedLoss,
    lossSelected,
    handleSelectedDiscared,
    discaredSelected,
    listTypeDecline,
    handlePreparedDiscarded,
    handlePreparedLoss,
    itemPreparationMappingNameSelected,
    itemPreparationMapping,
    handleItemPreparationMappingSelected,
    handleItemPreparationMappingNameSelected,
    itemPreparationMappingSelected,
    preparedDiscarded,
  } = props;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '608px',
    height: '530px',
    bgcolor: 'background.paper',
    p: 8,
  };
  const [discardedOrigenField, setdiscardedOrigenField] = React.useState(false);
  const [discardedDescriptionField, setDiscardedDescriptionField] = React.useState(false);
  const [preparationIdField, setPreparationIdField] = React.useState(false);
  const [requiredField, setrequiredField] = React.useState(false);

  const handleValidate = () => {
    setrequiredField(false);
    setdiscardedOrigenField(false);
    setDiscardedDescriptionField(false);
    setPreparationIdField(false);
    if (preparedDiscarded === 0 && discaredSelected === '') {
      setrequiredField(true);
    } else if (preparedDiscarded !== 0 && discaredSelected === '') {
      setdiscardedOrigenField(true);
    } else if (preparedDiscarded === 0 && discaredSelected !== '') {
      setDiscardedDescriptionField(true);
    } else if (
      itemPreparationMappingSelected === null ||
      itemPreparationMappingSelected === undefined
    ) {
      setPreparationIdField(true);
    } else {
      handleCreatedLosses();
      setrequiredField(false);
      setdiscardedOrigenField(false);
      setDiscardedDescriptionField(false);
      setPreparationIdField(false);
    }
  };
  const handleRestoreField = () => {
    setrequiredField(false);
    setdiscardedOrigenField(false);
    setDiscardedDescriptionField(false);
    setPreparationIdField(false);
  };
  return (
    <Modal
      open={openDialog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        handleCloseDialog();
        handleRestoreField();
      }}
    >
      <Grid container sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseDialog();
            handleRestoreField();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#00000',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid xs={12} md={12} mt={2}>
          <Typography
            variant="h5"
            color="#052550"
            gutterBottom
            fontFamily="Poppins"
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: {
                xl: 28,
                lg: 26,
                md: 24,
                sm: 18,
                xs: 14,
              },
            }}
          >
            AGREGAR MERMA O BAJA
          </Typography>
          <Typography
            color="#232323"
            gutterBottom
            fontFamily="Roboto"
            fontSize={16}
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            Ingresa las cantidades reales para la merma o baja
          </Typography>

          <Grid
            container
            sx={{
              width: '100%',
              verticalAlign: 'middle',
            }}
          >
            <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <FormControl
                fullWidth
                sx={{
                  mt: 2,
                  width: '50%',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Autocomplete
                  value={itemPreparationMappingSelected}
                  onChange={(event: any, newValue: ItemPreparationMapping | null) => {
                    handleItemPreparationMappingSelected(newValue);
                  }}
                  inputValue={itemPreparationMappingNameSelected}
                  onInputChange={(event, newInputValue) => {
                    handleItemPreparationMappingNameSelected(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={itemPreparationMapping}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 480 }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      error={preparationIdField}
                      {...params}
                      label="Insumo"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                <TextField
                  id="outlined-required"
                  label="Cantidad de Merma"
                  variant="outlined"
                  onChange={handlePreparedLoss}
                ></TextField>
              </FormControl>
              <FormControl
                fullWidth
                sx={{
                  mt: 2,
                  ml: 2,
                  width: '50%',
                }}
              >
                <InputLabel id="demo-simple-select-label">Origen</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Origen"
                  value={lossSelected}
                  onChange={handleSelectedLoss}
                >
                  {listTypeDecline
                    .filter((element) => element.type === 'LOSS')
                    .map((element) => (
                      <MenuItem value={element._id}>{element.description}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                <TextField
                  id="outlined-required"
                  label="Cantidad de Baja"
                  variant="outlined"
                  onChange={handlePreparedDiscarded}
                  error={discardedDescriptionField}
                ></TextField>
              </FormControl>
              <FormControl
                fullWidth
                sx={{
                  mt: 2,
                  ml: 2,
                  width: '50%',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
                error={discardedOrigenField}
              >
                <InputLabel id="demo-simple-select-label">Origen</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Origen"
                  value={discaredSelected}
                  onChange={handleSelectedDiscared}
                >
                  {listTypeDecline
                    .filter((element) => element.type === 'DISCARDED')
                    .map((element) => (
                      <MenuItem value={element._id}>{element.description}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Typography
            color={
              requiredField ||
              discardedOrigenField ||
              discardedDescriptionField ||
              preparationIdField
                ? '#FF0000'
                : '#FFFFFF'
            }
            gutterBottom
            fontFamily="Roboto"
            fontSize={16}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 1,
            }}
          >
            {requiredField ? ' Por favor ingresa una cantidad y origen.' : ''}
            {discardedOrigenField ? 'Por favor ingresa un origen para la baja.' : ''}
            {discardedDescriptionField ? 'Por favor ingresa una cantidad para la baja.' : ''}
            {preparationIdField ? 'Por favor selecciona un insumo' : ''}
          </Typography>
          <Card
            sx={{
              width: '100%',
              backgroundColor: '#052550',
              verticalAlign: 'middle',
              textAlign: 'center',
              height: '56px',
              mt: 2,
              borderRadius: '4px',
              top: '586px',
            }}
          >
            <CardActionArea sx={{ height: '100%' }} onClick={handleValidate}>
              <CardContent
                sx={{
                  verticalAlign: 'middle',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h5"
                  color="#FFFFFF"
                  gutterBottom
                  sx={{
                    margin: 0,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: {
                      xl: 28,
                      lg: 26,
                      md: 24,
                      sm: 18,
                      xs: 14,
                    },
                  }}
                >
                  CREAR MERMA O BAJA
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
