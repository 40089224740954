import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Checkbox, Grid } from '@mui/material';
import TimeFrame from '../../shared/models/TimeFrame';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Preparation } from '../../shared/models/Preparation';

interface ControlPreparationTableProps {
  preparations: Preparation[];
  timeFrames: TimeFrame[];
  showTable: boolean;
  handleChangeUpdatePreparationTimeFrame: (timeFrameId: string, preparation: Preparation) => void;
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F9FAFC',
    color: 'primary.main',
    height: '48px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ControlPreparationsTable = (props: ControlPreparationTableProps): React.ReactElement => {
  const { preparations, timeFrames, showTable, handleChangeUpdatePreparationTimeFrame } = props;

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        {showTable && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">INSTRUCCIONES</StyledTableCell>
                  <StyledTableCell>PREPARACIONES</StyledTableCell>
                  {timeFrames.map((item) => (
                    <StyledTableCell align="center">{item.description}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {preparations.map((item) => (
                  <TableRow key={item.preparationId}>
                    <TableCell align="center">
                      <ReceiptLongIcon
                        sx={{ mr: 2 }}
                        color={item.timeFrameIds.length !== 0 ? 'success' : 'warning'}
                      />
                    </TableCell>
                    <StyledTableCell color="success" component="th" scope="row">
                      {item.description}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={item.timeFrameIds.includes(timeFrames[0]._id || '')}
                        onChange={() =>
                          handleChangeUpdatePreparationTimeFrame(timeFrames[0]._id || '', item)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={item.timeFrameIds.includes(timeFrames[1]._id || '')}
                        onChange={() =>
                          handleChangeUpdatePreparationTimeFrame(timeFrames[1]._id || '', item)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={item.timeFrameIds.includes(timeFrames[2]._id || '')}
                        onChange={() =>
                          handleChangeUpdatePreparationTimeFrame(timeFrames[2]._id || '', item)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default ControlPreparationsTable;
