import React from 'react';
import axios from 'axios';
import { Station } from '../models/Station';

export interface StationByCountryState {
  data: Station[];
  isLoading: boolean;
}

const useStationByCountry = (country: string | undefined): StationByCountryState => {
  const [stations, setStations] = React.useState<Station[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getStations = async () => {
      setIsLoading(true);
      if (country) {
        const stations = await axios.get<Station[]>(
          `${process.env.REACT_APP_API_URL}/api/v1/stations/list/${country}`,
        );
        setStations(stations.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    getStations();
  }, [country]);

  return {
    data: stations,
    isLoading,
  };
};

export default useStationByCountry;
