import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { init as initAnalytics } from './utils/analytics';

axios.interceptors.request.use(
  (request) => {
    if (request && request.headers) {
      if (request.url?.includes("unsecure")) {
        return request;
      }
      const user = localStorage.getItem('ks.user');
      const kitchen = localStorage.getItem('ks.kitchen');
      if (user && kitchen) {
        request.headers['x-userDocument'] = JSON.parse(user).document;
        request.headers['x-userId'] = JSON.parse(user).id;
        request.headers['x-kichenId'] = JSON.parse(kitchen).kitchenId;
        request.headers['x-userToken'] = localStorage.getItem('ks.userToken') || ''
      } else {
        window.location.href = `${process.env.REACT_APP_KITCHEN_DISPLAY_URL!!}/display`
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

initAnalytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
