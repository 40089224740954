import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const GlobalMenuSkeleton = (): React.ReactElement => (
  <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={0.5}>
    <Divider sx={{ borderColor: '#D7D3D3', my: 1 }} textAlign="left" />
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={0.5}
    >
      <Skeleton variant="rectangular" width={32} height={32} />
      <Skeleton variant="text" width={40} />
    </Box>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={0.5}
    >
      <Skeleton variant="rectangular" width={32} height={32} />
      <Skeleton variant="text" width={40} />
    </Box>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={0.5}
    >
      <Skeleton variant="rectangular" width={32} height={32} />
      <Skeleton variant="text" width={40} />
    </Box>
  </Box>
);

export default React.memo(GlobalMenuSkeleton);
