import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PreparationState } from '../../shared/hooks/preparation.hook';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { HistoryTwoTone, CheckCircleOutlineRounded, PlayCircleRounded } from '@mui/icons-material';
import { Preparation } from '../../shared/models/Preparation';
import PreparationSkeleton from '../skeleton/PreparationsSkeleton';
import { Box } from '@mui/system';

interface PreparationTableProps {
  preparations: PreparationState;
  handleShowRecipe: (preparation: Preparation) => void;
  handlePreparation: (preparation: Preparation) => void;
  isSaving: Boolean;
  isLoading: Boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F9FAFC',
    color: theme.palette.common.black,
    height: '60px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const PreparationTable = (props: PreparationTableProps): React.ReactElement => {
  const { preparations, handleShowRecipe, handlePreparation, isSaving, isLoading } = props;
  const [lastSelectedPreparation, setLastSelectedPreparation] = React.useState<String>('');

  const preparationIsSaving = (preparation: Preparation): boolean => {
    return isSaving && preparation.preparationId === lastSelectedPreparation;
  };

  if (isLoading) {
    return <PreparationSkeleton />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Insumo</StyledTableCell>
            <StyledTableCell align="center">Cantidad sugerida</StyledTableCell>
            <StyledTableCell align="center">Unidad</StyledTableCell>
            <StyledTableCell align="center">Terminado</StyledTableCell>
            <StyledTableCell align="center" sx={{ display: 'none' }}>
              Instrucciones
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {preparations.data.map((item) => (
            <TableRow key={item.preparation._id}>
              <StyledTableCell component="th" scope="row">
                <IconButton
                  aria-label="state"
                  color={item.isPreparationComplete ? 'success' : 'warning'}
                >
                  {item.isPreparationComplete ? <CheckCircleOutlineRounded /> : <HistoryTwoTone />}
                </IconButton>
                {item.preparation.description}
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.validatedQuantity || item.suggestedQuantity || '-'}
              </StyledTableCell>
              <StyledTableCell align="center">{item.preparation.unit}</StyledTableCell>
              <StyledTableCell align="center">
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setLastSelectedPreparation(item.preparation.preparationId);
                      handlePreparation(item.preparation);
                    }}
                    disabled={item.isPreparationComplete || preparationIsSaving(item.preparation)}
                  >
                    {item.makePreparation ? (item.isPreparationComplete ? 'Terminado' : 'Terminar') : "NO PREPARAR"}
                  </Button>
                  {preparationIsSaving(item.preparation) && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'primary',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ display: 'none' }}>
                <IconButton
                  aria-label="fingerprint"
                  color="primary"
                  onClick={() => handleShowRecipe(item.preparation)}
                >
                  <PlayCircleRounded />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PreparationTable;
