import axios from 'axios';
import { DataLossesSave, ResponseLossesAdd } from '../models/LossAndDiscarded';

const saveLossesDetail = async (
  dataLossesDetailSave: DataLossesSave,
): Promise<ResponseLossesAdd> => {
    return await axios
      .post<ResponseLossesAdd>(
        `${process.env.REACT_APP_API_URL}/api/v1/loss-and-discarded/detail`,
        dataLossesDetailSave,
      )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return { losses:[] , isSuccessful: false, message: error.message };
      });
    };

export default saveLossesDetail;
