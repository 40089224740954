import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SelectorPreparationContainer from './miseenplace/preparation/selectorPreparation.container';
import SelectorStationContainer from './miseenplace/stationselector/Selector/selectorStation.container';
import SelectorMasterCheck from './miseenplace/masterCheck/Selector/selectorStation.container';
import SelectorControlPreparations from './miseenplace/parametrizePreparations/parametrizePreparations.container';

import Authenticator from './shared/components/Authenticate';

import DisplayTemplate from './shared/templates/DisplayNew';
import KitchenSelector from './shared/components/kitchenSelector';
import LossAndDiscardedContainer from './miseenplace/lossAndDiscarded/lossAndDiscardedAdmin.container';
import TaskBoard from './taskManager/boardArea/TaskBoard';

const routes = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <DisplayTemplate>
        <Routes>
          <Route path="/miseEnPlace" element={<SelectorStationContainer />} />
          <Route path="/:navigateTo/:userId/:userToken" element={<KitchenSelector />} />
          <Route path="/:navigateTo/:userId/:userToken/:kitchenId" element={<Authenticator />} />
          <Route
            path="/preparation/:stationId/:frameTimeId"
            element={<SelectorPreparationContainer />}
          />
          <Route path="/validate" element={<SelectorMasterCheck />} />
          <Route path="/preparations" element={<SelectorControlPreparations />} />
          <Route path="/lossAndDiscarded" element={<LossAndDiscardedContainer />} />
          <Route path="/board" element={<TaskBoard />} />
        </Routes>
      </DisplayTemplate>
    </BrowserRouter>
  );
};

export default routes;
