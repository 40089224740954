import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';

import GlobalMenuElement from './GlobalMenuElement';
import { User } from '../models/UserInformation';
import GlobalMenuSkeleton from './GlobalMenuSkeleton';


const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  overflowX: 'hidden',
  width: `${theme.spacing(0)}`,
  paddingLeft: 0,
  paddingRight: 0,
  [theme.breakpoints.up('lg')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
    paddingLeft: '2px',
    paddingRight: '2px',
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        paddingTop: theme.mixins.toolbar.height,
        paddingLeft: '12px',
        paddingRight: '12px',
        border: 0,
      },
      '& .user-info': {
        opacity: 1,
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        paddingTop: theme.mixins.toolbar.height,
        border: 0,
      },
      '& .user-info': {
        opacity: 0,
      },
    }),
  }),
);


interface MenuElement {
  icon: string;
  module: string;
  navigateTo: string;
  roles: string[];
  title: string;
  shortTitle: string;
  navigateEquals?: string[];
}

interface CategorizedMenu {
  [module: string]: MenuElement[];
}

interface GlobalMenuProps {
  user: User | null;
  drawerOpen: boolean;
  onBackAction: (() => void) | undefined;
  userMenu: MenuElement[] | null;
  moduleName: string;
  urlModuleMap: { [module: string]: string };
  loading: boolean;
  error: boolean;
  userToken: string;
  kitchenId: string;
}

const GlobalMenu = (props: GlobalMenuProps): React.ReactElement => {
  

  const {
    user,
    drawerOpen,
    onBackAction,
    userMenu,
    moduleName,
    urlModuleMap,
    loading,
    error,
  } = props;

  const location = useLocation();

  const [categorizedUserMenu, setCategorizedUserMenu] = React.useState<CategorizedMenu | null>(
    null,
  );

  const getUserImageUrlFromS3 = React.useCallback(
    (userId?: string): string => `https://images-kos.s3.amazonaws.com/users/${userId}.jpg`,
    [],
  );

  const getModuleName = (module: string) => {
    const moduleSplit = module.split('-');

    return moduleSplit[1];
  };

  const generateLink = (menuElement: MenuElement): string => {
    if (moduleName !== menuElement.module && !/^https?:\/\//.test(menuElement.navigateTo)) {
      return `${urlModuleMap[menuElement.module]}${menuElement.navigateTo}`;
    }
    return menuElement.navigateTo;
  };

  const activeModule = (menuElement: MenuElement): boolean => {
    let result = false;
    if (menuElement.navigateEquals?.length) {
      menuElement.navigateEquals.forEach((item) => {
        const urlPart = item.split('/');
        const locationPart = location.pathname.split('/');
        let coincidence = true;
        urlPart.forEach((part, index) => {
          if (!part.includes(':') && part) {
            coincidence = coincidence && locationPart[index] === part;
          }
        });
        result = result || coincidence;
      });
    }
    return result || new RegExp(`^${menuElement.navigateTo}$`, 'gi').test(location.pathname);
  };

  React.useEffect(() => {
    if (userMenu) {
      const newCategorizedUserMenu: CategorizedMenu = {
        'kitchen-display': [],
        'kitchen-staff': [],
        'kitchen-monitor': [],
      };

      userMenu.forEach((userMenuElement: MenuElement) => {
        if (!newCategorizedUserMenu[userMenuElement.module]) {
          newCategorizedUserMenu[userMenuElement.module] = [];
        }

        newCategorizedUserMenu[userMenuElement.module].push(userMenuElement);
      });

      setCategorizedUserMenu(newCategorizedUserMenu);
    }
  }, [userMenu]);

  return (
    <Drawer variant="permanent" open={drawerOpen} sx={{ zIndex: 14 }}>
      {user && (
        <>
          {drawerOpen && (
            <Box
              className="user-info"
              display="flex"
              px={1.5}
              pt={4}
              pb={2}
              gap={2}
              alignItems="center"
            >
              <Avatar
                src={getUserImageUrlFromS3(user?.id)}
                alt={user?.name.charAt(0)}
                sx={{ width: 40, height: 40 }}
              />
              <Box>
                <Typography variant="h6">{user?.name}</Typography>
                <Typography variant="body2">{user?.rol}</Typography>
              </Box>
            </Box>
          )}
          <List sx={{ pt: 1, pb: 0 }}>
            {onBackAction && (
              <GlobalMenuElement
                onClick={onBackAction}
                drawerOpen={drawerOpen}
                icon="chevron_left"
                label="Volver"
              />
            )}
            <GlobalMenuElement
              drawerOpen={drawerOpen}
              to={`${process.env.REACT_APP_KITCHEN_DISPLAY_URL}/`}
              icon="home_black"
              label="Inicio"
            />
          </List>
          <List sx={{ py: 0 }}>
            {loading && <GlobalMenuSkeleton/>}
            {error && <Alert severity="error" />}
            {!loading &&
              categorizedUserMenu &&
              Object.keys(categorizedUserMenu).map((module) => (
                <React.Fragment key={module}>
                  {categorizedUserMenu[module].length > 0 && (
                    <>
                      <Divider sx={{ borderColor: '#D7D3D3', my: 1 }} textAlign="left">
                        {drawerOpen && (
                          <Typography
                            variant="caption"
                            display="block"
                            color="text.secondary"
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {getModuleName(module)}
                          </Typography>
                        )}
                      </Divider>
                      {!drawerOpen && (
                        <Typography
                          variant="caption"
                          display="block"
                          color="text.secondary"
                          sx={{ pb: 1, textTransform: 'capitalize', textAlign: 'center' }}
                        >
                          {getModuleName(module)}
                        </Typography>
                      )}
                      {categorizedUserMenu[module].map((menuElement) => (
                        <GlobalMenuElement
                          key={`${menuElement.module}-${menuElement.title}`}
                          to={generateLink(menuElement)}
                          drawerOpen={drawerOpen}
                          icon={menuElement.icon}
                          label={menuElement.title}
                          shortLabel={menuElement.shortTitle}
                          selected={
                            menuElement.module === moduleName && activeModule(menuElement)
                          }
                        />
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
          </List>
        </>
      )}
    </Drawer>
  );
};

export default React.memo(GlobalMenu);