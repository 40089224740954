import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import React from "react";
import { Navigate, useParams } from "react-router";
import configProvider from "../../config.provider";
import useAuthenticate from "../hooks/authenticate.hook";

const Authenticator = (): React.ReactElement => {
  let { navigateTo, kitchenId } = useParams();

  const env = configProvider.env;
  const cookieUserId = Cookies.get(`${env}.userId`) || "";
  const cookieLoginToken = Cookies.get(`${env}.loginToken`) || "";

  const user = useAuthenticate(cookieUserId,cookieLoginToken, kitchenId!!);
  
  if (user.isLoading || !user.data.isAuthenticated) {
    return <CircularProgress />;
  } else {
    return <Navigate to={`/${navigateTo}`} />;
  }
};

export default Authenticator;
