import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Card,
  Grid,
  CardContent,
  TextField,
  FormControl,
  CardActionArea,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TypeDecline from '../shared/models/typeDecline';

export interface DialogDataProps {
  openDialog: boolean;
  handlePreparedQuantity: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handlePreparedLoss: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handlePreparedDiscarded: (event: { target: { value: React.SetStateAction<string> } }) => void;
  handleCloseValidatePreparationDialog: () => void;
  handleFinishPreparation: () => void;
  unit: string;
  listTypeDecline: TypeDecline[];
  handleSelectedLoss: (event: { target: { value: React.SetStateAction<string> } }) => void;
  lossSelected: string;
  handleSelectedDiscared: (event: { target: { value: React.SetStateAction<string> } }) => void;
  discaredSelected: string;
  country: string;
  lossField: boolean;
  discardedField: boolean;
}

export default function FinishValidatePreparationDialog(props: DialogDataProps) {
  const {
    openDialog,
    handlePreparedQuantity,
    handlePreparedLoss,
    handlePreparedDiscarded,
    handleCloseValidatePreparationDialog,
    handleFinishPreparation,
    unit,
    listTypeDecline,
    handleSelectedLoss,
    lossSelected,
    discaredSelected,
    handleSelectedDiscared,
    country,
    lossField,
    discardedField,
  } = props;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '608px',
    bgcolor: 'background.paper',
    p: 8,
  };
  return (
    <Modal
      open={openDialog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleCloseValidatePreparationDialog}
    >
      <Grid container sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleCloseValidatePreparationDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#00000',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid xs={12} md={12} mt={2}>
          <Typography
            variant="h5"
            color="#052550"
            gutterBottom
            fontFamily="Poppins"
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: {
                xl: 28,
                lg: 26,
                md: 24,
                sm: 18,
                xs: 14,
              },
            }}
          >
            Terminar Preparación
          </Typography>
          <Typography
            color="#232323"
            gutterBottom
            fontFamily="Roboto"
            fontSize={16}
            sx={{
              margin: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            Ingresa las cantidades reales de esta preparación
          </Typography>
          {country === 'UNDEFINDED' ? (
            <Grid
              container
              sx={{
                width: '100%',
                verticalAlign: 'middle',
              }}
            >
              <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                  <TextField
                    id="outlined-required"
                    label="Cantidad Preparada"
                    variant="outlined"
                    onChange={handlePreparedQuantity}
                  ></TextField>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ mt: 2, ml: 2, width: '50%', alignContent: 'center', textAlign: 'center' }}
                >
                  <Typography color="#000000" fontFamily="Roboto" fontSize={18} fontWeight={500}>
                    {unit.toUpperCase()}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <FormControl fullWidth sx={{ mt: lossField ? 2 : 0, width: '100%' }}>
                  <TextField
                    id="outlined-required"
                    label="Cantidad de Merma"
                    variant="outlined"
                    onChange={handlePreparedLoss}
                  ></TextField>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    mt: lossField ? 4 : 0,
                    ml: 2,
                    width: '50%',
                    alignContent: 'center',
                    textAlign: 'center',
                  }}
                  error={lossField}
                >
                  <InputLabel id="demo-simple-select-label">Origen</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Origen"
                    value={lossSelected}
                    onChange={handleSelectedLoss}
                  >
                    {listTypeDecline
                      .filter((element) => element.type === 'LOSS')
                      .map((element) => (
                        <MenuItem value={element._id}>{element.description}</MenuItem>
                      ))}
                  </Select>
                  {lossField ? <FormHelperText>Error</FormHelperText> : ''}
                </FormControl>
              </Grid>
              <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <FormControl fullWidth sx={{ mt: discardedField ? 2 : 0, width: '100%' }}>
                  <TextField
                    id="outlined-required"
                    label="Cantidad de Baja"
                    variant="outlined"
                    onChange={handlePreparedDiscarded}
                  ></TextField>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    mt: discardedField ? 4 : 0,
                    ml: 2,
                    width: '50%',
                    alignContent: 'center',
                    textAlign: 'center',
                  }}
                  error={discardedField}
                >
                  <InputLabel id="demo-simple-select-label">Origen</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Origen"
                    value={discaredSelected}
                    onChange={handleSelectedDiscared}
                  >
                    {listTypeDecline
                      .filter((element) => element.type === 'DISCARDED')
                      .map((element) => (
                        <MenuItem value={element._id}>{element.description}</MenuItem>
                      ))}
                  </Select>
                  {discardedField ? <FormHelperText>Error</FormHelperText> : ''}
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                width: '100%',
                verticalAlign: 'middle',
              }}
            >
              <Grid xs={12} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                  <TextField
                    id="outlined-required"
                    label="Cantidad Preparada"
                    variant="outlined"
                    onChange={handlePreparedQuantity}
                  ></TextField>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ mt: 2, ml: 2, width: '50%', alignContent: 'center', textAlign: 'center' }}
                >
                  <Typography color="#000000" fontFamily="Roboto" fontSize={18} fontWeight={500}>
                    {unit.toUpperCase()}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Card
            sx={{
              width: '100%',
              backgroundColor: '#052550',
              verticalAlign: 'middle',
              textAlign: 'center',
              height: '56px',
              mt: 3,
              borderRadius: '4px',
              top: '586px',
            }}
          >
            <CardActionArea sx={{ height: '100%' }} onClick={() => handleFinishPreparation()}>
              <CardContent
                sx={{
                  verticalAlign: 'middle',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h5"
                  color="#FFFFFF"
                  gutterBottom
                  sx={{
                    margin: 0,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: {
                      xl: 28,
                      lg: 26,
                      md: 24,
                      sm: 18,
                      xs: 14,
                    },
                  }}
                >
                  TERMINAR PREPARACIÓN
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
}
