import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router';

interface GlobalMenuElementProps {
  drawerOpen: boolean;
  icon: string;
  label: string;
  shortLabel?: string;
  to?: string;
  selected?: boolean;
  onClick?: () => void;
}

const GlobalMenuElement = (props: GlobalMenuElementProps): React.ReactElement => {
  const { drawerOpen, onClick, icon, label, to, shortLabel, selected } = props;

  const navigate = useNavigate();

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    } else if (to) {
      if (to.includes('http')) {
        window.location.href = to;
      } else {
        navigate(to);
      }
    }
  };

  return (
    <Tooltip title={label} placement="right" enterDelay={1500} arrow>
      <ListItemButton
        onClick={onClickHandler}
        sx={{
          minHeight: 48,
          justifyContent: drawerOpen ? 'initial' : 'center',
          px: 2.25,
          borderRadius: 1,
          flexDirection: drawerOpen ? 'row' : 'column',
          ...(selected && {
            bgcolor: '#121F56',
            '.MuiIcon-root': {
              color: 'white',
            },
            '.MuiTypography-root': {
              color: 'white',
            },
            '&:hover': {
              bgcolor: '#121F56',
              '.MuiIcon-root': {
                color: 'white',
              },
              '.MuiTypography-root': {
                color: 'white',
              },
            },
          }),
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: drawerOpen ? 3 : 0,
            justifyContent: 'center',
            flexDirection: drawerOpen ? 'row' : 'column',
          }}
        >
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText
          primary={!drawerOpen && shortLabel ? shortLabel : label}
          primaryTypographyProps={{
            variant: 'body2',
            fontSize: !drawerOpen ? '11px' : '14px',
            sx: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        />
      </ListItemButton>
    </Tooltip>
  );
};

GlobalMenuElement.defaultProps = {
  to: undefined,
  onClick: undefined,
  shortLabel: undefined,
  selected: undefined,
};

export default GlobalMenuElement;