import axios from 'axios';
import { KitchenTask } from '../models/KitchenTask';

const getKitchenTask = async (myUser:boolean) => {
  return await axios.get<KitchenTask[]>(
    `${process.env.REACT_APP_API_URL}/api/v1/task-manager/kitchen/myUser/${myUser}`,
  );
};

const updateTaskStatus = async (taskId: string, status: string) => {
  await axios.post<String>(
    `${process.env.REACT_APP_API_URL}/api/v1/task-manager/updateStatus`,
    {taskId,status}
  );
};

export { getKitchenTask, updateTaskStatus };
