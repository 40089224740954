import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const stationsCards = 6;

const StationsSkeleton = (): React.ReactElement => (
  <>
    {[...Array(stationsCards)].map((item: number) => (
      <Grid key={item} xs={12} md={6} lg={6} item>
        <Card>
          <Skeleton variant="rectangular" width="100%" height={120} />
          <CardContent>
            <Typography variant="h4">
              <Skeleton variant="text" />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </>
);

export default StationsSkeleton;
