import React from 'react';
import { useMount } from 'react-use';
import useCities from '../../shared/hooks/cities.hook';
import useKitchenByCitie from '../../shared/hooks/kitchenByCities.hook';
import usePreparationByStation from '../../shared/hooks/preparationByStation.hook';
import addNewPreparation from '../../shared/hooks/preparationNew.hook';
import preparationUpdate from '../../shared/hooks/preparationUpdate.hook';
import useStationByCountry from '../../shared/hooks/stationByCountry.hook';
import useTimeFrame from '../../shared/hooks/timeFrame.hook';
import useToolbarTitle from '../../shared/hooks/useToolbarTitle';
import { DialogData } from '../../shared/models/DialogData';
import { Preparation, PreparationNew } from '../../shared/models/Preparation';
import { KitchenByCity } from '../../shared/models/Station';
import { KitchenUser } from '../../shared/models/UserInformation';
import { AmplitudeEvent, logEvent } from '../../utils/analytics';
import SelectorControlPreparationsPageView from './parametrizePreparations.view';

const SelectorControlPreparations = (): React.ReactElement => {
  const timeFramesData = useTimeFrame();
  const cities = useCities();
  const [cityIdSelected, setCitySelected] = React.useState('');
  const kitchenByCity = useKitchenByCitie(cityIdSelected);
  const [kitchenIdSelected, setKitchenIdSelected] = React.useState('');
  const [kitchenIdFieldSelected, setKitchenIdFieldSelected] = React.useState('');
  const [kitchenSelected, setKitchenSelected] = React.useState<KitchenByCity>();
  const [countrySelected, setCountrySelected] = React.useState('');
  const stations = useStationByCountry(countrySelected);
  const [stationSelected, setStationSelected] = React.useState('');
  const preparations = usePreparationByStation(stationSelected, kitchenIdFieldSelected);
  const [filteredPreparations, setFilteredPreparations] = React.useState<Preparation[]>(
    preparations.data,
  );
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [showTable, setShowTable] = React.useState(false);
  const [openDialogNewPreparation, setOpenDialogNewPreparation] = React.useState(false);
  const [unit, setUnit] = React.useState<string>('');
  const [namePreparation, setNamePreparation] = React.useState<string>('');
  const [timeFramesNewPreparation, setTimeFramesNewPreparation] = React.useState<Array<string>>([]);
  const [requiredFieldNamePreparation, setRequiredFieldNamePreparation] =
    React.useState<boolean>(false);
  const [requiredFieldUnit, setRequiredFieldUnit] = React.useState<boolean>(false);
  const [filteredPreparationsDialog, setFilteredPreparationsDialog] = React.useState<Preparation[]>(
    preparations.data,
  );
  const defaultDialogData = { openDialog: false, title: '', message: '', response: false };
  const [openDialogFinishMiseEnPlace, setOpenDialogFinishMiseEnPlace] =
    React.useState<DialogData>(defaultDialogData);

  const handleChangeCitySelected = (event: { target: { value: React.SetStateAction<string> } }) => {
    setCitySelected(event.target.value);
    setCountrySelected('');
    setKitchenIdSelected('');
    setStationSelected('');
    setShowTable(false);
    setSearchTerm('');
  };

  const handleChangeKitchen = (event: { target: { value: React.SetStateAction<string> } }) => {
    const kitchenFiltered = kitchenByCity.data.filter(
      (element) => element._id === event.target.value,
    );
    setKitchenIdSelected(event.target.value);
    setCountrySelected(kitchenFiltered[0].country);
    setKitchenIdFieldSelected(kitchenFiltered[0].kitchenId);
    setKitchenSelected(kitchenFiltered[0]);
  };

  const handleChangeStation = (event: { target: { value: React.SetStateAction<string> } }) => {
    setStationSelected(event.target.value);
    setShowTable(true);
    setSearchTerm('');
  };

  const kitchen: KitchenUser = JSON.parse(localStorage.getItem('ks.kitchen')!!);
  const toolbarTitle = useToolbarTitle();

  useMount(() => {
    toolbarTitle.setTitle(kitchen?.name, 'SUGERIDOS');
  });

  const updatePreparationTimeFrame = async (timeFrameId: string, preparation: Preparation) => {
    if (preparation.timeFrameIds.includes(timeFrameId)) {
      preparation.timeFrameIds = preparation.timeFrameIds.filter(
        (element) => element !== timeFrameId,
      );
    } else {
      preparation.timeFrameIds.push(timeFrameId);
    }
    preparations.data.forEach((element) => {
      if (element._id === preparation._id) element = preparation;
    });
    await preparationUpdate(preparation);
    logEvent(AmplitudeEvent.UpdatePreparation);
    filterPreparations(searchTerm);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    filterPreparations(event.target.value);
    setSearchTerm(event.target.value);
  };

  const filterPreparations = (term: string) => {
    setFilteredPreparations(
      preparations.data.filter((prep) =>
        prep.description.toLocaleLowerCase().includes(term.toLowerCase()),
      ),
    );
  };
  const handleOpenDialog = (element: boolean) => {
    setRequiredFieldNamePreparation(false);
    setRequiredFieldUnit(false);
    setOpenDialogNewPreparation(element);
    setFilteredPreparationsDialog(preparations.data);
    setNamePreparation('');
    setUnit('');
    setSearchTerm('');
    setTimeFramesNewPreparation([]);
  };
  const handleUnitPreparationDialog = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUnit(event.target.value);
  };
  const handleNamePreparation = (event: { target: { value: React.SetStateAction<string> } }) => {
    setNamePreparation(event.target.value);
    setFilteredPreparationsDialog(
      preparations.data.filter((prep) =>
        prep.description.toLocaleLowerCase().includes(event.target.value.toString().toLowerCase()),
      ),
    );
  };
  const handleTimeFramePreparation = (timeFrameId: string) => {
    if (timeFramesNewPreparation.includes(timeFrameId)) {
      setTimeFramesNewPreparation(
        timeFramesNewPreparation.filter((element) => element !== timeFrameId),
      );
    } else {
      timeFramesNewPreparation.push(timeFrameId);
    }
  };
  const handleAddNewPreparation = async () => {
    if (namePreparation !== '' && unit !== '' && kitchenSelected !== undefined) {
      setRequiredFieldNamePreparation(false);
      setRequiredFieldUnit(false);
      const preparation: PreparationNew = {
        unit: unit,
        description: namePreparation,
        stationId: stationSelected,
        kitchen: kitchenSelected,
        timeFrameIds: timeFramesNewPreparation,
      };

      const response = await addNewPreparation(preparation);
      if (response) {
        setNamePreparation('');
        setOpenDialogNewPreparation(false);
        setTimeFramesNewPreparation([]);
        const stationTemp: string = stationSelected;
        setStationSelected('');
        setStationSelected(stationTemp);
        setOpenDialogFinishMiseEnPlace({
          openDialog: true,
          title: response.isSuccessful ? 'Preparación Creada' : 'Error',
          message: response.message,
          response: response.isSuccessful,
        });
      }
    } else {
      namePreparation === ''
        ? setRequiredFieldNamePreparation(true)
        : setRequiredFieldNamePreparation(false);
      unit === '' ? setRequiredFieldUnit(true) : setRequiredFieldUnit(false);
    }
  };
  const handleCloseDialogSavePreparation = () => {
    setOpenDialogFinishMiseEnPlace({
      openDialog: false,
      title: '',
      message: '',
      response: false,
    });
  };
  const handleEditPreparation = (namePreparation: string) => {
    setFilteredPreparations([]);
    setNamePreparation('');
    setUnit('');
    setOpenDialogNewPreparation(false);
    filterPreparations(namePreparation);
    setSearchTerm(namePreparation);
  };

  return (
    <SelectorControlPreparationsPageView
      timeFrames={timeFramesData.data}
      cities={cities.data}
      citySelected={cityIdSelected}
      kitchenByCity={kitchenByCity.data}
      kitchenIdSelected={kitchenIdSelected}
      stations={stations.data}
      stationSelected={stationSelected}
      preparations={searchTerm !== '' ? filteredPreparations : preparations.data}
      isLoading={preparations.isLoading}
      showTable={showTable}
      handleChangeKitchen={handleChangeKitchen}
      handleChangeCitySelected={handleChangeCitySelected}
      handleChangeStation={handleChangeStation}
      updatePreparationTimeFrame={updatePreparationTimeFrame}
      handleChangeSearch={handleChangeSearch}
      searchTerm={searchTerm}
      openDialogNewPreparation={openDialogNewPreparation}
      handleOpenDialog={handleOpenDialog}
      handleUnitPreparationDialog={handleUnitPreparationDialog}
      unitPreparation={unit}
      handleNamePreparation={handleNamePreparation}
      handleTimeFramePreparation={handleTimeFramePreparation}
      handleAddNewPreparation={handleAddNewPreparation}
      requiredFieldNamePreparation={requiredFieldNamePreparation}
      requiredFieldUnit={requiredFieldUnit}
      handleCloseDialogSavePreparation={handleCloseDialogSavePreparation}
      filteredPreparationsDialog={filteredPreparationsDialog}
      handleEditPreparation={handleEditPreparation}
      openDialogFinishMiseEnPlace={openDialogFinishMiseEnPlace}
    />
  );
};

export default SelectorControlPreparations;
