import { Alert, AlertTitle, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import React, { useEffect } from 'react';
import GlobalMenu from '../../components/GlobalMenu';
import Header from '../../components/Header';
import { Menu } from '../../models/Menu';
import { KitchenUser, User } from '../../models/UserInformation';

import DisplayTemplateContext from './contexts/displayTemplate.context';

interface DisplayTemplateViewProps {
  children: React.ReactNode;
}

const DisplayTemplateView = (props: DisplayTemplateViewProps): React.ReactElement => {
  const { children } = props;

  const { alert, hideAlert, onBackAction, drawerOpen } = React.useContext(DisplayTemplateContext);

  const user: User = localStorage.getItem('ks.user') ? JSON.parse(localStorage.getItem('ks.user') || '') : null;
  const kitchen: KitchenUser = localStorage.getItem('ks.kitchen') ? JSON.parse(localStorage.getItem('ks.kitchen') || '') : null;
  
  const [userMenu, setUserMenu] = React.useState<Menu[] | null>(null);
  const [userMenuIsLoading, setUserMenuIsLoading] = React.useState<boolean>(false);
  const [userMenuError, setUserMenuError] = React.useState<boolean>(false);

  const handleCloseAlert = () => {
    if (hideAlert) {
      hideAlert();
    }
  };

  const getUserMenu = React.useCallback(async () => {
    if (user && !userMenuIsLoading && !userMenu) {
      setUserMenuError(false);
      setUserMenuIsLoading(true);
      const getUserMenuResponse = await axios.get<Menu[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/menu`
      ).catch((error) => {
        setUserMenuError(true);
        setUserMenuIsLoading(false);
        throw new Error(error);
      });
      setUserMenu(getUserMenuResponse.data);
      setUserMenuIsLoading(false);
    }
  }, [user, userMenu, userMenuIsLoading]);

  useEffect(() => {
    getUserMenu();
  }, [getUserMenu]);

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100vh',
        pt: ({ mixins }) => `${mixins.toolbar.height}px`,
        display: 'flex',
      }}
    >
      <Snackbar
        open={alert.show}
        onClose={handleCloseAlert}
        autoHideDuration={alert.duration}
        anchorOrigin={alert.position}
      >
        <Alert variant="filled" severity={alert.severity} sx={{ minWidth: 360 }}>
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </Alert>
      </Snackbar>

      <Header />

      <React.Suspense
        fallback={
          <Skeleton
            variant="rectangular"
            height={4}
            sx={{ bgcolor: 'white', position: 'fixed', top: 0, width: '100%' }}
          />
        }
      >
        <GlobalMenu
          user={user}
          onBackAction={onBackAction ? () => onBackAction() : undefined}
          drawerOpen={drawerOpen}
          userMenu={userMenu}
          moduleName="kitchen-staff"
          urlModuleMap={{
            'kitchen-staff': '',
            'kitchen-monitor': `${process.env.REACT_APP_KITCHEN_MONITOR_URL}` ?? '',
            'kitchen-display': `${process.env.REACT_APP_KITCHEN_DISPLAY_URL}` ?? '',
          }}
          loading={userMenuIsLoading}
          userToken={localStorage.getItem('ks.userToken') || ''}
          kitchenId={kitchen?.kitchenId ?? ''}
          error={userMenuError}
        />

        <Box component="main" sx={{ display: 'flex', flexGrow: 1, minHeight: '100%' }}>
          {children}
        </Box>
      </React.Suspense>
    </Box>
  );
};

export default DisplayTemplateView;
