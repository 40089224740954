import axios from 'axios';
import { PreparationNew, ResponsePreparationAdd } from '../models/Preparation';

const addNewPreparation = async (preparation: PreparationNew): Promise<ResponsePreparationAdd> => {
  return await axios
    .post<ResponsePreparationAdd>(
      `${process.env.REACT_APP_API_URL}/api/v1/preparation`,
      preparation,
    )
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return { isSuccessful: false, message: error.message };
    });
};

export default addNewPreparation;
