import React from 'react';
import axios from 'axios';
import { TaskUser } from '../models/KitchenTask';

export interface TaskUserState {
  data: TaskUser[];
  isLoading: boolean;
}

const UseUserByName = (name: string | null): TaskUserState => {
  const [taskUsers, setTaskUsers] = React.useState<TaskUser[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getUsers = async () => {
      setIsLoading(true);
      const users = await axios.get<TaskUser[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/kitchenos/users/name/${name}`,
      );
      setTaskUsers(users.data);
      setIsLoading(false);
    };

    getUsers();
  }, [name]);

  return {
    data: taskUsers,
    isLoading,
  };
};

export default UseUserByName;
