import React from 'react';
import { useMount } from 'react-use';
import usePrediction from '../../../shared/hooks/prediction.hook';
import stationPredictionUpdate from '../../../shared/hooks/stationPredcitionUpdate.hook';
import useTimeFrame from '../../../shared/hooks/timeFrame.hook';
import useToolbarTitle from '../../../shared/hooks/useToolbarTitle';
import { DialogData } from '../../../shared/models/DialogData';
import { Prediction } from '../../../shared/models/Prediction';
import TimeFrame from '../../../shared/models/TimeFrame';
import { KitchenUser } from '../../../shared/models/UserInformation';
import { AmplitudeEvent, logEvent } from '../../../utils/analytics';
import SelectorPageView from './selectorStation.view';

const SelectorStationContainer = (): React.ReactElement => {
  const [selectedFrame, setSelectedFrame] = React.useState<TimeFrame>();
  const timeFramesData = useTimeFrame();
  const [dayOfWeek, setDayOfWeek] = React.useState(new Date().getDay());
  const stationsData = usePrediction(selectedFrame || timeFramesData.selectedFrame, dayOfWeek);
  const predictions: Prediction[] | undefined = [];
  const defaultDialogData = { openDialog: false, title: '', message: '', response: false };
  const [openDialogFinishMiseEnPlace, setOpenDialogFinishMiseEnPlace] =
    React.useState<DialogData>(defaultDialogData);

  const handleDayOfWeekSelect = async (day: number) => {
    setDayOfWeek(day);
  };

  const handleTimeFrameSelect = (timeFrame: TimeFrame) => {
    setSelectedFrame(timeFrame);
  };
  const kitchen: KitchenUser = JSON.parse(localStorage.getItem('ks.kitchen')!!);

  const toolbarTitle = useToolbarTitle();

  useMount(() => {
    toolbarTitle.setTitle(kitchen?.name, 'SUGERIDOS');
  });

  const handleChange =
    (preparationId: string | undefined) => (event: { target: { value: any } }) => {
      if (!isNaN(Number(event.target.value))) {
        stationsData.data.forEach((element) => {
          let prediction = element.predictions.find((item) => item.preparationId === preparationId);
          if (prediction !== undefined) {
            prediction.validateQuantity = Number(event.target.value);
            predictions.push(prediction);
          }
        });
      }
    };

  const handleCloseDialogMiseEnpLace = () => {
    setOpenDialogFinishMiseEnPlace(defaultDialogData);
  };

  const handleFinishPrediction = async () => {
    const filteredPrediction = predictions.filter(
      (element, pos) => predictions.indexOf(element) === pos,
    );
    const response = await stationPredictionUpdate(filteredPrediction);
    logEvent(AmplitudeEvent.SaveQuantity);

    setOpenDialogFinishMiseEnPlace({
      openDialog: true,
      title: 'Prediciones Actualizadas',
      message: response.message,
      response: response.isSuccessful,
    });
  };

  return (
    <SelectorPageView
      stationsData={stationsData.data}
      isLoading={stationsData.isLoading}
      handleDayOfWeekSelect={handleDayOfWeekSelect}
      onTimeFrameClick={handleTimeFrameSelect}
      timeFrames={timeFramesData.data}
      previousTimeFrame={selectedFrame || timeFramesData.selectedFrame}
      handleChange={handleChange}
      dayOfWeek={dayOfWeek}
      handleFinishPrediction={handleFinishPrediction}
      onClickDialog={handleCloseDialogMiseEnpLace}
      dialog={openDialogFinishMiseEnPlace}
    />
  );
};

export default SelectorStationContainer;
